import React from "react";

import { OrderStatus } from "../apiClients/common";
import { RadioGroup, RadioGroupOption, RadioGroupProps } from "../lib/Input/RadioGroup";
import Messages from "../locale/en.json";

const options: RadioGroupOption[] = [
  {
    id: OrderStatus.NewOrder,
    text: Messages.orderStatus.new,
  },
  {
    id: OrderStatus.Processing,
    text: Messages.orderStatus.processing,
  },
  {
    id: OrderStatus.Printing,
    text: Messages.orderStatus.printing,
  },
  {
    id: OrderStatus.Complete,
    text: Messages.orderStatus.complete,
  },
];

export type OrderStatusRadioGroupProps = Omit<RadioGroupProps, "options">;

export const OrderStatusRadioGroup = (props: OrderStatusRadioGroupProps): JSX.Element => {
  return <RadioGroup {...props} options={options} />;
};
