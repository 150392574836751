import React from "react";

import { ProductDetails } from "../apiClients/common";
import { FlowType } from "../lib/Callout";
import { DecimalInput } from "../lib/Input/DecimalInput";
import { TextInput } from "../lib/Input/TextInput";
import { InputFormCallout, InputFormCalloutProps } from "../lib/InputForm/InputFormCallout";
import Messages from "../locale/en.json";

export enum Fields {
  Name = "name",
  Price = "price",
}

export interface ProductConfigCalloutProps extends Omit<InputFormCalloutProps, "children" | "flow"> {
  productDetails?: ProductDetails;
}

export const ProductConfigCallout = ({ productDetails, ...rest }: ProductConfigCalloutProps): JSX.Element => {
  return (
    <InputFormCallout flow={FlowType.Overlay} {...rest}>
      <TextInput
        autoComplete="off"
        fieldName={Fields.Name}
        label={Messages.labels.name}
        defaultValue={productDetails?.name}
        required
      />
      <DecimalInput
        required
        fieldName={Fields.Price}
        label={Messages.labels.basePrice}
        defaultValue={productDetails?.price}
      />
    </InputFormCallout>
  );
};
