import React from "react";

import { Delete } from "@mui/icons-material";
import { IconButton, ListItem } from "@mui/material";

import { AttachmentDetails, NoteDetails, UserDetails, UserType } from "../apiClients/common";
import { DateFormat, formatDate } from "../helpers/dateHelper";
import Messages from "../locale/en.json";
import { FileLink } from "./FileLink";
import { NewNote } from "./PurchaseOrder";

export interface NoteCardProps {
  note: NewNote | NoteDetails;
  user: UserDetails;
  onDelete?: () => void;
}

export const NoteCard = ({ note, user, onDelete }: NoteCardProps): JSX.Element => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isNewNote = React.useMemo(() => !(note as NoteDetails).id, []);

  const userName = React.useMemo(
    () =>
      isNewNote
        ? `${user.firstName} ${user.lastName}`
        : `${(note as NoteDetails).user.firstName} ${(note as NoteDetails).user.lastName}`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const datePosted = React.useMemo(() => {
    const date = isNewNote ? new Date() : (note as NoteDetails).createdOn;
    return formatDate(date, DateFormat.LongDateTime);
  }, [note, isNewNote]);

  const links = React.useMemo(
    () =>
      note.attachments?.length ? (
        <span>
          {note.attachments?.map((file, index) => (
            <React.Fragment key={file.name}>
              <FileLink
                title={file.name}
                download={user.type !== UserType.Client}
                url={
                  (file as AttachmentDetails).id ? (file as AttachmentDetails).path : URL.createObjectURL(file as File)
                }
              />
              {index < (note.attachments?.length ?? 0) - 1 ? <span>, </span> : null}
            </React.Fragment>
          ))}
        </span>
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isDeleted = !!(note as NoteDetails).deletedOn;

  return (
    <ListItem
      style={{
        padding: 0,
        display: "flex",
        flexFlow: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "1rem",
      }}
    >
      <div
        style={{
          textDecoration: isDeleted ? "line-through" : undefined,
          display: "flex",
          flexFlow: "column",
          gap: "1rem",
          alignItems: "flex-start",
          textAlign: "left",
        }}
      >
        <div style={{ display: "flex", flexFlow: "row", gap: "1rem", flexGrow: 1 }}>
          <span>{userName}</span>
          <span>{datePosted}</span>
        </div>
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            gap: "1rem",
            flexGrow: 3,
          }}
        >
          <span>{note.note}</span>
          {links}
        </div>
      </div>
      {(!(note as NoteDetails).user || (note as NoteDetails).user?.id === user.id) && !isDeleted ? (
        <IconButton onClick={onDelete} aria-label={Messages.common.delete}>
          <Delete />
        </IconButton>
      ) : null}
    </ListItem>
  );
};
