export enum DateFormat {
  ShortDate = "ShortDate",
  ShortDateTime = "ShortDateTime",
  LongDate = "LongDate",
  LongDateTime = "LongDateTime",
}
export const formatDate = (toFormat: number | Date, format: DateFormat): string => {
  const date = new Date(toFormat);

  switch (format) {
    case DateFormat.LongDate:
      // Thursday June 16 2022
      return date.toLocaleDateString(undefined, { weekday: "long", month: "long", day: "numeric", year: "numeric" });
    case DateFormat.LongDateTime:
      // Thursday June 16 2022 08:20:00 AM
      return `${date.toLocaleString(undefined, {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        hour12: true,
        minute: "numeric",
      })}`;
    case DateFormat.ShortDate:
      // 16/06/22
      return date.toLocaleDateString();
    case DateFormat.ShortDateTime:
      // 16/06/22 8:20:00 AM
      return date.toLocaleString();
    default:
      return "";
  }
};
