import React from "react";

import { ResponseError } from "../apiClients/common";
import { useBulkMutation, UseBulkMutationProps, UseBulkMutationResult } from "./useBulkMutation";

export interface UseBulkMutationCallProps<DataType, ArgsType> extends UseBulkMutationProps<ArgsType, DataType> {
  onSuccess?: (response: UseBulkMutationResult<ArgsType, DataType>["data"]) => void;
  onFailure?: (error: ResponseError[]) => void;
}

export type UseBulkMutationCallResult<DataType, ArgsType> = Pick<
  UseBulkMutationResult<ArgsType, DataType>,
  "invoke" | "loading"
>;

export const useBulkMutationCall = <DataType, ArgsType>({
  onFailure,
  onSuccess,
  ...rest
}: UseBulkMutationCallProps<DataType, ArgsType>): UseBulkMutationCallResult<DataType, ArgsType> => {
  const { loading, data, error, invoke } = useBulkMutation<ArgsType, DataType>({ ...rest });
  const invoked = React.useRef<boolean>(false);

  const internalInvoke = React.useCallback((args: ArgsType[]) => {
    if (!invoked.current) {
      invoked.current = true;
      return invoke(args);
    }
  }, []) as UseBulkMutationCallResult<DataType, ArgsType>["invoke"];

  React.useEffect(() => {
    if (!loading && invoked.current) {
      const err: ResponseError[] | undefined = error?.filter((e) => !!e) as ResponseError[] | undefined;
      if (err?.length) {
        onFailure?.(err);
      } else {
        onSuccess?.(data);
      }
      invoked.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), JSON.stringify(error), loading, invoked.current]);

  return {
    loading,
    invoke: internalInvoke,
  };
};
