import React from "react";

import { Search } from "@mui/icons-material";
import { InputAdornment, InputProps, ListSubheader, MenuItem, TextField, TextFieldProps } from "@mui/material";

import Messages from "../../locale/en.json";
import { FormContext, ValidationMode, ValidationModeContext, ValidationState } from "./Form";

export interface SelectOption {
  id: string;
  text: string;
  data?: unknown;
}

export interface SelectProps extends Pick<TextFieldProps, "required" | "disabled" | "label" | "placeholder"> {
  fieldName: string;
  defaultValue?: string;
  options: SelectOption[];
  onChange?: (value: SelectOption) => void;
  validator?: (value?: SelectOption) => string[];
  /**
   * @default "18rem"
   */
  width?: React.CSSProperties["width"];
  loading?: boolean;
  search?: boolean;
}

export const Select = ({
  defaultValue,
  fieldName,
  onChange,
  options,
  validator,
  disabled,
  label,
  placeholder,
  required,
  width = "18rem",
  loading,
  search,
}: SelectProps): JSX.Element => {
  const form = React.useContext(FormContext);
  const validationMode = React.useContext(ValidationModeContext);

  const [errors, setErrors] = React.useState<string[]>([]);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [filteredOptions, setFilteredOptions] = React.useState<SelectOption[]>(options);

  const fieldValidation = (): void => {
    const errorMessages: string[] = [];
    const value = form.getValue<SelectOption>(fieldName);
    errorMessages.push(...(validator?.(value) ?? []));
    if (required && !value) {
      errorMessages.push(Messages.validation.required);
    }
    setErrors(errorMessages);
    form.setFieldState(errorMessages.length ? ValidationState.INVALID : ValidationState.VALID, fieldName);
  };

  const internalOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value || undefined;
    const selectedOption = value ? options.find(({ id }) => id === value) : undefined;
    form.setValue(selectedOption, fieldName);
    onChange?.(selectedOption as SelectOption);
    if (required || validator) {
      fieldValidation();
    }
    setFilteredOptions(options);
    setSearchValue("");
  };

  React.useEffect(() => {
    if (required || validator) {
      form.registerField(fieldName);
    }

    if (defaultValue) {
      const defaultOption = options.find(({ id }) => defaultValue === id);
      form.setValue(defaultOption, fieldName);
      if (required || validator) {
        fieldValidation();
      }
    }

    return function cleanup() {
      if (required || validator) {
        form.unregisterField(fieldName);
      }
      form.deleteField(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (validationMode === ValidationMode.ON) {
      if (required || validator) {
        fieldValidation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode]);

  React.useEffect(() => {
    const newFilteredOptions = options.filter((opt) =>
      opt.text.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    );
    setFilteredOptions(newFilteredOptions);
  }, [searchValue]);

  React.useEffect(() => {
    setSearchValue("");
    setFilteredOptions(options);
  }, [options]);

  const menuItems = React.useMemo(
    () =>
      filteredOptions.map((option) => (
        <MenuItem id={option.id} key={option.id} value={option.id}>
          {option.text}
        </MenuItem>
      )),
    [filteredOptions]
  );

  return (
    <TextField
      select
      style={{ width }}
      error={errors.length > 0}
      helperText={errors.join(" ")}
      onChange={internalOnChange}
      required={required}
      disabled={loading || disabled}
      label={label}
      defaultValue={defaultValue}
      placeholder={placeholder}
      SelectProps={{
        MenuProps: { autoFocus: !search },
      }}
    >
      {search && <InternalSearch value={searchValue} onChange={setSearchValue} />}
      {menuItems}
    </TextField>
  );
};

const stopKeydownPropogation = (e: React.KeyboardEvent): void => {
  if (e.key !== "Escape") {
    e.stopPropagation();
  }
};

const searchIcon = (
  <InputAdornment position="start">
    <Search />
  </InputAdornment>
);

const inputProps: Partial<InputProps> = { startAdornment: searchIcon };

interface InternalSearchProps {
  onChange?: (value: string) => void;
  value: string;
}

const InternalSearch = ({ value, onChange }: InternalSearchProps): JSX.Element => {
  const internalOnChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      onChange?.(e.currentTarget.value);
    },
    [onChange]
  );

  return (
    <ListSubheader>
      <TextField
        fullWidth
        size="small"
        aria-label={Messages.labels.search}
        placeholder={Messages.labels.search}
        defaultValue={value}
        value={value}
        onKeyDown={stopKeydownPropogation}
        InputProps={inputProps}
        onChange={internalOnChange}
        autoFocus
      />
    </ListSubheader>
  );
};
