import React from "react";

import { NewPurchaseOrderBody } from "../apiClients/common";
import { FlowType } from "../lib/Callout";
import { TextInput } from "../lib/Input/TextInput";
import { InputFormCallout, InputFormCalloutProps } from "../lib/InputForm/InputFormCallout";
import Messages from "../locale/en.json";
import { ConfirmType, ConfirmTypeSelect } from "./ConfirmTypeSelect";
import { PropertyTypeSelect } from "./PropertyTypeSelect";

export enum Fields {
  PurchaseOrderNumber = "purchaseOrderNumber",
  Address = "address",
  City = "city",
  Intersection = "intersection",
  Agent = "agent",
  PropertyType = "propertyType",
  SignColours = "signColours",
  PermissionFromOwnerGiven = "permissionFromOwnerGiven",
}

export interface PurchaseOrderDetailsCalloutProps
  extends Pick<InputFormCalloutProps, "componentRef" | "errors" | "title" | "onSubmit" | "onDiscard"> {
  purchaseOrderDetails?: NewPurchaseOrderBody;
}

export const PurchaseOrderDetailsCallout = ({
  componentRef,
  errors,
  onSubmit,
  title,
  onDiscard,
  purchaseOrderDetails,
}: PurchaseOrderDetailsCalloutProps): JSX.Element => (
  <InputFormCallout
    onDiscard={onDiscard}
    componentRef={componentRef}
    errors={errors}
    onSubmit={onSubmit}
    title={title}
    flow={FlowType.Overlay}
  >
    <TextInput
      autoComplete="off"
      defaultValue={purchaseOrderDetails?.purchaseOrderNumber}
      fieldName={Fields.PurchaseOrderNumber}
      label={Messages.labels.purchaseOrderNumber}
    />
    <TextInput
      autoComplete="off"
      defaultValue={purchaseOrderDetails?.address}
      fieldName={Fields.Address}
      label={Messages.labels.address}
    />
    <TextInput
      autoComplete="off"
      defaultValue={purchaseOrderDetails?.city}
      fieldName={Fields.City}
      label={Messages.labels.city}
    />
    <TextInput
      autoComplete="off"
      defaultValue={purchaseOrderDetails?.intersection}
      fieldName={Fields.Intersection}
      label={Messages.labels.intersection}
    />
    <TextInput
      defaultValue={purchaseOrderDetails?.agent}
      fieldName={Fields.Agent}
      label={Messages.labels.agent}
      autoComplete="off"
    />
    <PropertyTypeSelect
      defaultValue={purchaseOrderDetails?.propertyType}
      fieldName={Fields.PropertyType}
      label={Messages.labels.propertyType}
    />
    <TextInput
      autoComplete="off"
      defaultValue={purchaseOrderDetails?.signColours}
      fieldName={Fields.SignColours}
      label={Messages.labels.signColours}
    />
    <ConfirmTypeSelect
      defaultValue={purchaseOrderDetails?.permissionFromOwnerGiven ? ConfirmType.Yes : ConfirmType.No}
      fieldName={Fields.PermissionFromOwnerGiven}
      label={Messages.labels.permissionFromOwnerGiven}
    />
  </InputFormCallout>
);
