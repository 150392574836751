import React from "react";

import { useMutation, UseMutationProps, UseMutationResult } from "./useMutation";

export interface UseMutationCallProps<DataType, ArgsType> extends UseMutationProps<ArgsType, DataType> {
  onSuccess?: (response: UseMutationResult<ArgsType, DataType>["data"]) => void;
  onFailure?: (error: UseMutationResult<ArgsType, DataType>["error"]) => void;
}

export type UseMutationCallResult<DataType, ArgsType> = Pick<UseMutationResult<ArgsType, DataType>, "invoke" | "loading">;

export const useMutationCall = <DataType, ArgsType>({
  onFailure,
  onSuccess,
  ...rest
}: UseMutationCallProps<DataType, ArgsType>): UseMutationCallResult<DataType, ArgsType> => {
  const { loading, data, error, invoke } = useMutation<ArgsType, DataType>({ ...rest });
  const invoked = React.useRef<boolean>(false);

  const internalInvoke = React.useCallback((args: ArgsType) => {
    invoked.current = true;
    return invoke(args);
  }, []) as UseMutationCallResult<DataType, ArgsType>["invoke"];

  React.useEffect(() => {
    if (!loading && invoked.current) {
      if (error) {
        onFailure?.(error);
      } else {
        onSuccess?.(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), JSON.stringify(error), loading, invoked.current]);

  return {
    loading,
    invoke: internalInvoke,
  };
};
