import { buildUrl, handleResponse, ParsedResponse, ProductDetails } from "./common";

export type ProductDetailsCollection = ProductDetails[];

export type GetProductsResponse = ProductDetailsCollection;
type GetProductsReqQuery = { search?: string };

export const getProducts = async (query?: GetProductsReqQuery): Promise<ParsedResponse<GetProductsResponse>> =>
  fetch(buildUrl("/api/products", query), {
    method: "GET",
    credentials: "include",
  }).then(handleResponse<GetProductsResponse>);

export interface FindProductsByIdBody {
  id: number;
}

export type FindProductByIdResponse = ProductDetails;

export const findProductsById = async (body: FindProductsByIdBody): Promise<ParsedResponse<FindProductByIdResponse>> =>
  fetch(`/api/products/${body.id}`, {
    method: "GET",
    credentials: "include",
  }).then(handleResponse<FindProductByIdResponse>);

export interface DeleteProductBody {
  id: number;
}

export type DeleteProductResponse = ProductDetails;

export const deleteProduct = async (body: DeleteProductBody): Promise<ParsedResponse<DeleteProductResponse>> =>
  fetch(`/api/products/${body.id}`, {
    method: "DELETE",
    credentials: "include",
  }).then(handleResponse<DeleteProductResponse>);

export type UpdateProductBody = Pick<ProductDetails, "id"> &
  Partial<Omit<ProductDetails, "id" | "createdOn" | "updatedOn">>;
export type UpdateProductResponse = ProductDetails;

export const updateProduct = async (body: UpdateProductBody): Promise<ParsedResponse<UpdateProductResponse>> =>
  fetch(`/api/products/${body.id}`, {
    method: "PUT",
    body: JSON.stringify(body),
    credentials: "include",
  }).then(handleResponse<UpdateProductResponse>);

export type CreateProductBody = Omit<ProductDetails, "id" | "createdOn" | "updatedOn">;
export type CreateProductResponse = ProductDetails;

export const createProduct = async (body: CreateProductBody): Promise<ParsedResponse<CreateProductResponse>> =>
  fetch("/api/products", {
    method: "POST",
    body: JSON.stringify(body),
    credentials: "include",
  }).then(handleResponse<CreateProductResponse>);
