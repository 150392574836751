import React from "react";

import { Select, SelectOption, SelectProps } from "../lib/Input/Select";
import Messages from "../locale/en.json";

export enum ConfirmType {
  Yes = "yes",
  No = "no",
}

const confirmOptions: SelectOption[] = [
  {
    id: ConfirmType.Yes,
    text: Messages.common.yes,
  },
  {
    id: ConfirmType.No,
    text: Messages.common.no,
  },
];

export type ConfirmTypeSelectProps = Omit<SelectProps, "options">;

export const ConfirmTypeSelect = (props: ConfirmTypeSelectProps): JSX.Element => (
  <Select options={confirmOptions} {...props} />
);
