import React from "react";

import { UserDetails, UserType } from "../apiClients/common";
import { FlowType } from "../lib/Callout";
import { getValue } from "../lib/Input/Form";
import { SelectOption } from "../lib/Input/Select";
import { InputFormCallout, InputFormCalloutProps } from "../lib/InputForm/InputFormCallout";
import Messages from "../locale/en.json";
import { UserSelect } from "./UserSelect";

enum Fields {
  Client = "Client",
}

interface ClientCalloutProps extends Pick<InputFormCalloutProps, "title" | "onDiscard"> {
  onSubmit: (client: UserDetails) => void;
}

export const ClientCallout = ({ onSubmit, title, onDiscard }: ClientCalloutProps): JSX.Element => {
  return (
    <InputFormCallout
      flow={FlowType.Overlay}
      title={title}
      onSubmit={(values) => {
        onSubmit(getValue<SelectOption>(values, Fields.Client)?.data as UserDetails);
      }}
      onDiscard={onDiscard}
    >
      <UserSelect type={UserType.Client} fieldName={Fields.Client} label={Messages.labels.client} required />
    </InputFormCallout>
  );
};
