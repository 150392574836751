import { PropertyType, UserType } from "../apiClients/common";
import Messages from "../locale/en.json";

export const getUserTypeValue = (type: UserType): string => {
  switch (type) {
    case UserType.Admin:
      return Messages.userType.admin;
    case UserType.SuperAdmin:
      return Messages.userType.superAdmin;
    case UserType.Client:
      return Messages.userType.client;
    default:
      return type;
  }
};

export const getPropertyTypeValue = (type: PropertyType | undefined): string => {
  switch (type) {
    case PropertyType.ConstructionSite:
      return Messages.propertyType.constructionSite;
    case PropertyType.Office:
      return Messages.propertyType.office;
    case PropertyType.Other:
      return Messages.propertyType.other;
    default:
      return type ?? "";
  }
};
