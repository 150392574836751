import { handleResponse, ParsedResponse, UserDetails } from "./common";

export interface LoginArgs {
  email: string;
  password: string;
}

export type LoginResponse = UserDetails;

export const login = async (args: LoginArgs): Promise<ParsedResponse<UserDetails>> =>
  fetch("/api/auth/login", { method: "POST", body: JSON.stringify(args) }).then(handleResponse<LoginResponse>);

export const logout = async (): Promise<ParsedResponse<void>> =>
  await fetch("/api/auth/logout", { method: "POST", credentials: "include" }).then(handleResponse<void>);

export type IdentityResponse = UserDetails;

export const identity = async (): Promise<ParsedResponse<UserDetails>> =>
  fetch("/api/auth/identity", {
    method: "POST",
    credentials: "include",
  }).then(handleResponse<IdentityResponse>);
