import React from "react";
import { v4 as uuid } from "uuid";

import { Add, Edit } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";

import {
  NewPurchaseOrderBody,
  NewPurchaseOrderLine,
  NoteDetails,
  OrderStatus,
  ProductDetails,
  PropertyType,
  PurchaseOrderLineSummary,
  PurchaseOrderSummary,
  UpdatePurchaseOrderBody,
  UserDetails,
  UserType,
} from "../apiClients/common";
import { getProducts } from "../apiClients/products";
import { CreatePurchaseOrderBody, UpdatePurchaseOrderDetailsBody } from "../apiClients/purchaseOrders";
import { CreateNote, Fields as NoteFields } from "../components/CreateNote";
import {
  Fields as PurchaseOrderDetailsFields,
  PurchaseOrderDetailsCallout,
} from "../components/PurchaseOrderDetailsCallout";
import { Fields as PurchaseOrderItemsFields, PurchaseOrderItemsCallout } from "../components/PurchaseOrderItemsCallout";
import { DateFormat, formatDate } from "../helpers/dateHelper";
import { getPropertyTypeValue } from "../helpers/resourceHelper";
import { useQueryCall } from "../hooks/useQueryCall";
import { Action, ActionBar, ActionBarInternal } from "../lib/ActionBar";
import { FlowType } from "../lib/Callout";
import { DisplayList } from "../lib/DisplayList";
import { FormValues, getValue } from "../lib/Input/Form";
import { RadioGroupOption } from "../lib/Input/RadioGroup";
import { SelectOption } from "../lib/Input/Select";
import { TextInput } from "../lib/Input/TextInput";
import { InputFormCallout } from "../lib/InputForm/InputFormCallout";
import { Column, HasId, Listing } from "../lib/Listing";
import { MetaItemDisplay, MetaItemSection } from "../lib/MetaItemSection";
import { NotificationManagerContext } from "../lib/NotificationManager";
import { NotificationType } from "../lib/NotificationTypes";
import Messages from "../locale/en.json";
import { AuthContext } from "./AuthContextProvider";
import { ConfirmType } from "./ConfirmTypeSelect";
import { NoteCard } from "./NoteCard";
import { Status } from "./Status";
import { Fields as PurchaseOrderStatusFields, StatusConfig } from "./StatusConfig";

enum ActionIds {
  AddNote = "AddNote",
  EditDetails = "EditDetails",
  EditItems = "EditItems",
  UpdateStatus = "UpdateStatus",
  EditUserNotes = "EditUserNotes",
}

enum PanelIds {
  Details = "Details",
  Items = "Items",
  Note = "Note",
  ChangeStatus = "Status",
  UserNotes = "UserNotes",
}

enum MetaItemIds {
  OrderNumber = "orderNumber",
  PurchaseOrderNumber = "purchaseOrderNumber",
  DateCreated = "createdOn",
  DateCompleted = "completedOn",
  Property = "propertyType",
  SignColours = "signColours",
  Address = "address",
  City = "city",
  Intersection = "intersection",
  Company = "company",
  Agent = "agent",
  PermissionFromOwner = "permissionFromOwnerGiven",
}

enum ColumnIds {
  Item = "name",
  Quantity = "quantity",
  BasePrice = "basePrice",
  Price = "price",
  Total = "Total",
}

enum Fields {
  Details = "Details",
}

const getColumns = (products: ProductDetails[] | undefined, userType: UserType): Column[] => {
  const columns: Column[] = [
    {
      id: ColumnIds.Item,
      text: Messages.labels.item,
      minWidth: 100,
      onRender: (val: string) => products?.find((prod) => prod.id === Number(val))?.name ?? val,
    },
    {
      id: ColumnIds.Quantity,
      text: Messages.labels.quantity,
      minWidth: 100,
    },
  ];

  if (userType !== UserType.Client) {
    columns.push({
      id: ColumnIds.BasePrice,
      text: Messages.labels.basePrice,
      minWidth: 100,
      onRender: (val: string) => `$${val}`,
    });
  }

  columns.push(
    {
      id: ColumnIds.Price,
      text: Messages.labels.price,
      minWidth: 100,
      onRender: (val: string) => `$${val}`,
    },
    {
      id: ColumnIds.Total,
      text: Messages.common.total,
      minWidth: 100,
      onRender: (val: string) => `$${val}`,
    }
  );

  return columns;
};

const getMetaItems = (purchaseOrderDetails?: Partial<PurchaseOrderSummary>, client?: UserDetails): MetaItemSection[] => [
  [
    {
      id: MetaItemIds.OrderNumber,
      title: Messages.labels.orderNumber,
      description: purchaseOrderDetails?.id?.toString(),
    },
    {
      id: MetaItemIds.PurchaseOrderNumber,
      title: Messages.labels.purchaseOrderNumber,
      description: purchaseOrderDetails?.purchaseOrderNumber,
    },
    {
      id: MetaItemIds.DateCreated,
      title: Messages.labels.dateCreated,
      description: formatDate(purchaseOrderDetails?.createdOn ?? new Date(), DateFormat.ShortDate),
    },
    {
      id: MetaItemIds.DateCompleted,
      title: Messages.labels.dateCompleted,
      description: purchaseOrderDetails?.completedOn
        ? formatDate(purchaseOrderDetails.completedOn, DateFormat.ShortDate)
        : undefined,
    },
    {
      id: MetaItemIds.Property,
      title: Messages.labels.propertyType,
      description: getPropertyTypeValue(purchaseOrderDetails?.propertyType),
    },
    {
      id: MetaItemIds.SignColours,
      title: Messages.labels.signColours,
      description: purchaseOrderDetails?.signColours,
    },
  ],
  [
    {
      id: MetaItemIds.Address,
      title: Messages.labels.address,
      description: purchaseOrderDetails?.address,
    },
    {
      id: MetaItemIds.City,
      title: Messages.labels.city,
      description: purchaseOrderDetails?.city,
    },
    {
      id: MetaItemIds.Intersection,
      title: Messages.labels.intersection,
      description: purchaseOrderDetails?.intersection,
    },
    {
      id: MetaItemIds.Company,
      title: Messages.labels.company,
      description: purchaseOrderDetails?.client?.company ?? client?.company,
    },
    {
      id: MetaItemIds.Agent,
      title: Messages.labels.agent,
      description: purchaseOrderDetails?.agent,
    },
    {
      id: MetaItemIds.PermissionFromOwner,
      title: Messages.labels.permissionFromOwnerGiven,
      description: purchaseOrderDetails?.permissionFromOwnerGiven ? Messages.common.yes : Messages.common.no,
    },
  ],
];

export type NewNote = { key: string; note: string; attachments: File[]; deltedOn?: string };

export interface PurchaseOrderProps {
  user: UserDetails;
  client?: UserDetails;
  details?: NewPurchaseOrderBody | UpdatePurchaseOrderBody;
  onDetailsChange?: (details: NewPurchaseOrderBody | UpdatePurchaseOrderDetailsBody) => void;
  notes: (NewNote | NoteDetails)[];
  onAddNote?: (note: NewNote) => void;
  onDeleteNote?: (note: NoteDetails | NewNote) => void;
  items: (NewPurchaseOrderLine | PurchaseOrderLineSummary)[];
  onItemsChange?: (items: (NewPurchaseOrderLine | PurchaseOrderLineSummary)[]) => void;
  status?: OrderStatus;
  onStatusChange?: (status: OrderStatus) => void;
  userNotes?: string;
  onUserNotesChange?: (userNotes: string) => void;
}

export const PurchaseOrder = ({
  details,
  items,
  notes,
  user,
  client,
  onDetailsChange,
  onItemsChange,
  onAddNote,
  onDeleteNote,
  status,
  onStatusChange,
  userNotes,
  onUserNotesChange,
}: PurchaseOrderProps): JSX.Element => {
  const auth = React.useContext(AuthContext);
  const notificationManager = React.useContext(NotificationManagerContext);

  const [columns, setColumns] = React.useState<Column[]>([]);

  useQueryCall({
    method: getProducts,
    onSuccess: (data) => setColumns(getColumns(data, user.type)),
    onFailure: (err) => {
      if (err) {
        if (err.status === 401) {
          auth.logout();
        } else {
          notificationManager.push({
            message: err.message,
            title: Messages.errors.loadProducts,
            type: NotificationType.Error,
          });
        }
      }
    },
  });

  const [openPanel, setOpenPanel] = React.useState<PanelIds>();
  const closePanels = (): void => {
    setOpenPanel(undefined);
  };

  const metaItemsActions: Action[] = [];

  const canUpdateStatus = !!onStatusChange;

  if (status) {
    metaItemsActions.push({
      id: ActionIds.UpdateStatus,
      value: (
        <div>
          <Status type={status} />
        </div>
      ),
      onClick: canUpdateStatus ? () => setOpenPanel(PanelIds.ChangeStatus) : undefined,
    });
  }

  const canEditDetails = !!onDetailsChange;

  if (canEditDetails) {
    metaItemsActions.push({
      id: ActionIds.EditDetails,
      value: <Edit />,
      onClick: () => setOpenPanel(PanelIds.Details),
    });
  }

  const userNotesActions: Action[] = [];

  const isAdmin = user.type === UserType.Admin || user.type === UserType.SuperAdmin;

  if (isAdmin) {
    userNotesActions.push({
      id: ActionIds.EditUserNotes,
      value: !userNotes ? <Add /> : <Edit />,
      onClick: () => setOpenPanel(PanelIds.UserNotes),
    });
  }

  const productListActions: Action[] = [];

  const canEditItems = !!onItemsChange;

  if (canEditItems) {
    productListActions.push({
      id: ActionIds.EditItems,
      value: items.length ? <Edit /> : <Add />,
      onClick: () => setOpenPanel(PanelIds.Items),
    });
  }

  const noteDisplayActions: Action[] = [
    {
      id: ActionIds.AddNote,
      value: <Add />,
      onClick: () => setOpenPanel(PanelIds.Note),
    },
  ];

  const total = items
    .reduce(
      (sum, itemEntry) => (sum += Number.parseFloat(itemEntry[ColumnIds.Price] ?? "0") * itemEntry[ColumnIds.Quantity]),
      0
    )
    .toFixed(2);

  const onCreateNote = (values: FormValues): void => {
    const note: NewNote = {
      key: uuid(),
      note: getValue<string>(values, NoteFields.Note) ?? "",
      attachments: getValue<File[]>(values, NoteFields.Attachments) ?? [],
    };
    onAddNote?.(note);
    closePanels();
  };

  const onDetailsSubmit = (formValues: FormValues): void => {
    const newDetails: CreatePurchaseOrderBody["details"] = {
      permissionFromOwnerGiven:
        getValue<SelectOption>(formValues, PurchaseOrderDetailsFields.PermissionFromOwnerGiven)?.id === ConfirmType.Yes,
      address: getValue<string>(formValues, PurchaseOrderDetailsFields.Address),
      agent: getValue<string>(formValues, PurchaseOrderDetailsFields.Agent),
      city: getValue<string>(formValues, PurchaseOrderDetailsFields.City),
      intersection: getValue<string>(formValues, PurchaseOrderDetailsFields.Intersection),
      propertyType: getValue<SelectOption>(formValues, PurchaseOrderDetailsFields.PropertyType)?.id as
        | PropertyType
        | undefined,
      signColours: getValue<string>(formValues, PurchaseOrderDetailsFields.SignColours),
      purchaseOrderNumber: getValue<string>(formValues, PurchaseOrderDetailsFields.PurchaseOrderNumber),
    };
    onDetailsChange?.(newDetails);
    closePanels();
  };

  const onStatusSubmit = (values: FormValues): void => {
    const orderStatus = getValue<RadioGroupOption>(values, PurchaseOrderStatusFields.Status)?.id as OrderStatus;
    onStatusChange?.(orderStatus);
    closePanels();
  };

  const onItemsSubmit = (values: FormValues): void => {
    const itemsValue = getValue<FormValues[]>(values, PurchaseOrderItemsFields.Items) ?? [];

    const enteredItems: NewPurchaseOrderLine[] = itemsValue.map((lineItem) => {
      const itemOption = getValue<SelectOption>(lineItem, PurchaseOrderItemsFields.Item);
      const item = itemOption?.data as ProductDetails | undefined;
      const basePrice = item?.price ? parseFloat(item.price) : 0;
      const priceOverride = getValue<string>(lineItem, PurchaseOrderItemsFields.Price);
      const pricePerItem = priceOverride ? parseFloat(priceOverride) : 0;
      const price = pricePerItem || basePrice;

      const quantity = getValue<string>(lineItem, PurchaseOrderItemsFields.Quantity);
      const lineTotal = price * (quantity ? parseInt(quantity, 10) : 0);

      return {
        [PurchaseOrderItemsFields.Quantity]: Number(quantity ?? ""),
        [PurchaseOrderItemsFields.Price]: price.toFixed(2),
        [PurchaseOrderItemsFields.Total]: lineTotal.toFixed(2),
        [PurchaseOrderItemsFields.Item]: itemOption?.data as ProductDetails,
        createdOn: new Date(),
        updatedOn: new Date(),
      };
    });

    onItemsChange?.(enteredItems);
    closePanels();
  };

  const onUserNotesSubmit = (values: FormValues): void => {
    const newUserNotes: string = getValue<string>(values, Fields.Details) ?? "";
    onUserNotesChange?.(newUserNotes);
    closePanels();
  };

  const getPanel = (): JSX.Element | null => {
    switch (openPanel) {
      case PanelIds.Details:
        return (
          <PurchaseOrderDetailsCallout
            purchaseOrderDetails={details}
            title={Messages.common.details}
            onSubmit={onDetailsSubmit}
            onDiscard={closePanels}
          />
        );
      case PanelIds.Items:
        return (
          <PurchaseOrderItemsCallout
            userType={user.type}
            items={items.map((item) => ({
              [PurchaseOrderItemsFields.Price]: item.price ?? "",
              [PurchaseOrderItemsFields.Item]:
                typeof item.product === "number"
                  ? (item.product as number).toString()
                  : item.product?.id.toString() ?? "",
              [PurchaseOrderItemsFields.Quantity]: item.quantity.toString(),
              [PurchaseOrderItemsFields.Total]: item.quantity * Number.parseFloat(item.price ?? ""),
            }))}
            onDiscard={closePanels}
            onSubmit={onItemsSubmit}
            title={Messages.common.items}
          />
        );
      case PanelIds.Note:
        return <CreateNote onSubmit={onCreateNote} title={Messages.panels.addNote.title} onDiscard={closePanels} />;
      case PanelIds.ChangeStatus:
        return (
          <StatusConfig
            status={status as OrderStatus}
            title={Messages.panels.updateStatus.title}
            onDiscard={closePanels}
            onSubmit={onStatusSubmit}
          />
        );
      case PanelIds.UserNotes:
        return (
          <InputFormCallout
            flow={FlowType.Overlay}
            onSubmit={onUserNotesSubmit}
            title={`${Messages.common.edit} ${Messages.labels.userNotes}`}
            onDiscard={closePanels}
          >
            <TextInput
              fieldName={Fields.Details}
              defaultValue={userNotes}
              label={Messages.labels.userNotes}
              multiline={{ minRows: 4 }}
            />
          </InputFormCallout>
        );
      default:
        return null;
    }
  };

  const deleteNote = (note: NoteDetails | NewNote) => (): void => {
    onDeleteNote?.(note);
  };

  const displayItems: (Record<ColumnIds, string> & HasId)[] = items.map((item) => ({
    id: (item as PurchaseOrderLineSummary | undefined)?.id ? (item as PurchaseOrderLineSummary).id.toString() : uuid(),
    [ColumnIds.Item]: (item as PurchaseOrderLineSummary | undefined)?.name || item.product?.name || "",
    [ColumnIds.Quantity]: item.quantity.toString(),
    [ColumnIds.BasePrice]: item.product?.price ?? Number.parseFloat(item.price ?? "0").toFixed(2),
    [ColumnIds.Price]: item.price ? Number.parseFloat(item.price).toFixed(2) : item.product?.price ?? "",
    [ColumnIds.Total]: (Number.parseFloat(item.price ?? item.product?.price ?? "0") * item.quantity).toFixed(2),
  }));

  return (
    <>
      <MetaItemDisplay
        title={Messages.common.details}
        ActionBarProps={{
          variant: "h5",
          variantMapping: { h5: "h2" },
        }}
        actions={metaItemsActions}
        sections={getMetaItems(details, client)}
      />
      {isAdmin ? (
        <Paper elevation={16} style={{ overflow: "auto" }}>
          <div style={{ padding: !userNotes?.length ? "2rem" : "2rem 2rem 0 2rem" }}>
            <ActionBarInternal
              actions={userNotesActions}
              title={Messages.labels.userNotes}
              variant="h5"
              variantMapping={{ h5: "h2" }}
            />
          </div>
          {userNotes?.length ? <Typography style={{ padding: "2rem" }}>{userNotes}</Typography> : null}
        </Paper>
      ) : null}
      <Listing
        title={Messages.common.items}
        ActionBarProps={{
          variant: "h5",
          variantMapping: { h5: "h2" },
        }}
        actions={productListActions}
        columns={columns}
        items={displayItems}
      />
      <ActionBar
        title={Messages.common.total}
        variant="h5"
        variantMapping={{ h5: "h2" }}
        actions={[
          {
            id: "total",
            value: `$${total}`,
          },
        ]}
      />
      <DisplayList
        title={Messages.common.notes}
        actions={noteDisplayActions}
        ActionBarProps={{
          variant: "h5",
          variantMapping: { h5: "h2" },
        }}
      >
        {notes.map<JSX.Element>((note) => (
          <NoteCard note={note} user={user} key={note.note} onDelete={deleteNote(note)} />
        ))}
      </DisplayList>
      {getPanel()}
    </>
  );
};
