import React from "react";

import { PurchaseOrderSummary, UserDetails, UserType } from "../apiClients/common";
import { DateFormat, formatDate } from "../helpers/dateHelper";
import { Column, Listing, ListingFilterProps, ListingProps, SortOrder } from "../lib/Listing";
import Messages from "../locale/en.json";

export enum ColumnIds {
  CompletedOn = "completedOn",
  Created = "createdOn",
  OrderNumber = "id",
  PurchaseOrderNumber = "purchaseOrderNumber",
  Address = "address",
  City = "city",
  Company = "company",
  // joined product titles
  Order = "items",
  UpdatedOn = "updatedOn",
  UserNotes = "userNotes",
  SubmittedBy = "client",
}

const getColumns = (type?: UserType): Column[] => {
  const columns: Column[] = [
    {
      id: ColumnIds.OrderNumber,
      text: Messages.labels.orderNumber,
      minWidth: 100,
    },
    {
      id: ColumnIds.PurchaseOrderNumber,
      text: Messages.labels.purchaseOrderNumber,
      minWidth: 100,
    },
  ];

  if (type === UserType.Admin || type === UserType.SuperAdmin) {
    columns.push({
      id: ColumnIds.Company,
      text: Messages.labels.company,
      minWidth: 150,
    });
  }

  if (type === UserType.Client) {
    columns.push({
      id: ColumnIds.SubmittedBy,
      text: Messages.labels.submittedBy,
      minWidth: 100,
      onRender: (user: UserDetails) => `${user.firstName} ${user.lastName}`,
    });
  }

  columns.push(
    {
      id: ColumnIds.Address,
      text: Messages.labels.address,
      minWidth: 150,
    },
    {
      id: ColumnIds.City,
      text: Messages.labels.city,
      minWidth: 100,
    },
    {
      id: ColumnIds.Order,
      text: Messages.labels.order,
      minWidth: 200,
    }
  );

  if (type !== UserType.Client) {
    columns.push({
      id: ColumnIds.UserNotes,
      text: Messages.labels.userNotes,
      minWidth: 200,
    });
  }

  columns.push(
    {
      id: ColumnIds.Created,
      text: Messages.labels.dateCreated,
      minWidth: 100,
      initialSortOrder: type === UserType.Admin || type === UserType.SuperAdmin ? SortOrder.Desc : undefined,
      onRender: (val: Date) => formatDate(val, DateFormat.ShortDateTime),
    },
    {
      id: ColumnIds.UpdatedOn,
      text: Messages.labels.dateUpdated,
      minWidth: 100,
      initialSortOrder: type === UserType.Client ? SortOrder.Desc : undefined,
      onRender: (val: Date) => formatDate(val, DateFormat.ShortDateTime),
    }
  );

  return columns;
};

export interface PurchaseOrderListProps
  extends Pick<
    ListingProps<Date | string, PurchaseOrderSummary>,
    "loading" | "items" | "onSelect" | "onRowClick" | "selectionMode"
  > {
  userType?: UserType;
  filters?: ListingFilterProps<Date | string, PurchaseOrderSummary>[];
}

export const PurchaseOrderList = ({ userType, filters, ...rest }: PurchaseOrderListProps): JSX.Element => {
  const columns = React.useMemo(() => getColumns(userType), []);

  return <Listing filters={filters} columns={columns} {...rest} />;
};
