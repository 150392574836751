import React from "react";

import { PropertyType } from "../apiClients/common";
import { Select, SelectOption, SelectProps } from "../lib/Input/Select";
import Messages from "../locale/en.json";

const userTypeOptions: SelectOption[] = [
  {
    id: PropertyType.Office,
    text: Messages.propertyType.office,
  },
  {
    id: PropertyType.ConstructionSite,
    text: Messages.propertyType.constructionSite,
  },
  {
    id: PropertyType.Other,
    text: Messages.propertyType.other,
  },
];

export type PropertyTypeSelectProps = Omit<SelectProps, "options">;

export const PropertyTypeSelect = (props: PropertyTypeSelectProps): JSX.Element => (
  <Select options={userTypeOptions} {...props} />
);
