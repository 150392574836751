import React from "react";

import { IconButton, Paper, Typography, TypographyProps } from "@mui/material";

export interface Action {
  id: string;
  disabled?: boolean;
  onClick?: () => void;
  value: JSX.Element | string;
}

export interface ActionBarProps {
  title: string;
  /**
   * @default h4
   */
  variant?: TypographyProps["variant"];
  /**
   * @default {h4:"h1"}
   */
  variantMapping?: TypographyProps["variantMapping"];
  actions?: Action[];
}

export const ActionBar = (props: ActionBarProps): JSX.Element => (
  <Paper elevation={16} sx={{ padding: "1rem" }}>
    <ActionBarInternal {...props} />
  </Paper>
);

export const ActionBarInternal = ({
  actions,
  title,
  variant = "h4",
  variantMapping = { h4: "h1" },
}: ActionBarProps): JSX.Element => (
  <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
    <Typography style={{ height: "min-content", wordWrap: "normal" }} variant={variant} variantMapping={variantMapping}>
      {title}
    </Typography>
    <div>
      {actions?.length
        ? actions.map((action) => (
            <IconButton onClick={action.onClick} key={action.id} disabled={action.disabled}>
              {action.value}
            </IconButton>
          ))
        : null}
    </div>
  </div>
);
