import React from "react";

export type FormValues = Record<string, unknown>;

export enum ValidationState {
  UNKNOWN = "UNKNOWN",
  VALID = "VALID",
  INVALID = "INVALID",
}

export const getValue = <ValueType,>(formValues: FormValues, fieldName: string): ValueType | undefined =>
  formValues[fieldName] as ValueType | undefined;

export interface FormState {
  registerField: (fieldName: string) => void;
  unregisterField: (fieldName: string) => void;
  deleteField: (fieldName: string) => void;
  getValue: <ValueType>(fieldName: string) => ValueType | undefined;
  setValue: (value: unknown, fieldName: string) => void;
  setFieldState: (validationState: ValidationState, fieldName: string) => void;
  getFieldState: (fieldName: string) => ValidationState | undefined;
  getOverallState: () => ValidationState;
}

export interface InternalFormState extends FormState {
  getValues: () => FormValues;
}

class FormStateImpl implements FormState {
  private onValueUpdate?: OnValueUpdateCallback;

  private onStateUpdate?: OnStateUpdateCallback;

  private values: Record<string, unknown> = {};

  private states: Record<string, ValidationState> = {};

  constructor(_onValueUpdate?: OnValueUpdateCallback, _onStatesUpdate?: OnStateUpdateCallback) {
    this.onStateUpdate = _onStatesUpdate;
    this.onValueUpdate = _onValueUpdate;
  }

  registerField = (fieldName: string): void => {
    this.states[fieldName] = ValidationState.UNKNOWN;
    this.onStateUpdate?.(this.getOverallState(), ValidationState.UNKNOWN, fieldName);
  };

  unregisterField = (fieldName: string): void => {
    delete this.states[fieldName];
    this.onStateUpdate?.(this.getOverallState(), undefined, fieldName);
  };

  deleteField = (fieldName: string): void => {
    delete this.values[fieldName];
    this.onValueUpdate?.(this.values, undefined, fieldName);
  };

  getValue = <ValueType,>(fieldName: string): ValueType | undefined => getValue<ValueType>(this.values, fieldName);

  setValue = (value: unknown, fieldName: string): void => {
    this.values[fieldName] = value;
    this.onValueUpdate?.(this.values, value, fieldName);
  };

  setFieldState = (validationState: ValidationState, fieldName: string): void => {
    this.states[fieldName] = validationState;
    this.onStateUpdate?.(this.getOverallState(), validationState, fieldName);
  };

  getFieldState = (fieldName: string): ValidationState | undefined => this.states[fieldName];

  getValues = (): FormValues => this.values;

  getOverallState = (): ValidationState => {
    const states = Object.values(this.states);
    if (states.some((state) => state === ValidationState.UNKNOWN)) return ValidationState.UNKNOWN;
    if (states.every((state) => state === ValidationState.VALID)) return ValidationState.VALID;
    return ValidationState.INVALID;
  };
}

export enum ValidationMode {
  ON = "ON",
  OFF = "OFF",
}

export const FormContext = React.createContext<FormState>({} as FormState);

export type OnValueUpdateCallback = (formValues: FormValues, value: unknown | undefined, fieldName: string) => void;
export type OnStateUpdateCallback = (
  overallState: ValidationState,
  fieldState: ValidationState | undefined,
  fieldName: string
) => void;

interface FormStateContextProviderProps {
  children?: JSX.Element;
  componentRef?: (ref: InternalFormState) => void;
  onValueUpdate?: OnValueUpdateCallback;
  onStatesUpdate?: OnStateUpdateCallback;
}

const FormStateContextProvider = ({
  componentRef,
  children,
  onStatesUpdate,
  onValueUpdate,
}: FormStateContextProviderProps): JSX.Element => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formState = React.useMemo<InternalFormState>(() => new FormStateImpl(onValueUpdate, onStatesUpdate), []);

  React.useEffect(() => {
    componentRef?.({ ...formState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef]);

  return <FormContext.Provider value={{ ...formState }}>{children}</FormContext.Provider>;
};

export const ValidationModeContext = React.createContext<ValidationMode>(ValidationMode.OFF);

export interface FormComponentRef {
  submit: () => void;
  enableValidation: () => void;
}

export interface FormProps {
  children: React.ReactNode;
  onSubmit?: (values: FormValues) => void;
  componentRef?: (ref: FormComponentRef) => void;
  onValueUpdate?: OnValueUpdateCallback;
  onStatesUpdate?: OnStateUpdateCallback;
}

export const Form = ({ children, onSubmit, componentRef, onStatesUpdate, onValueUpdate }: FormProps): JSX.Element => {
  const formState = React.useRef<InternalFormState>();
  const setFormState = (ref: InternalFormState): void => {
    formState.current = ref;
  };
  const [validationMode, setValidationMode] = React.useState<ValidationMode>(ValidationMode.OFF);

  const submit = (): void => {
    onSubmit?.(formState.current?.getValues() ?? {});
  };

  const enableValidation = (): void => {
    setValidationMode(ValidationMode.ON);
  };

  React.useEffect(() => {
    componentRef?.({
      submit,
      enableValidation,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef]);

  return (
    <FormStateContextProvider componentRef={setFormState} onStatesUpdate={onStatesUpdate} onValueUpdate={onValueUpdate}>
      <ValidationModeContext.Provider value={validationMode}>{children}</ValidationModeContext.Provider>
    </FormStateContextProvider>
  );
};
