import React from "react";

import { ParsedResponse, ResponseError } from "../apiClients/common";

export interface UseMutationResult<ArgsType, DataType> extends ParsedResponse<DataType> {
  loading: boolean;
  invoke: ArgsType extends never ? () => void : (args?: ArgsType) => void;
}

export interface UseMutationProps<ArgsType, DataType> {
  method: ArgsType extends never
    ? () => Promise<ParsedResponse<DataType>>
    : (args: ArgsType) => Promise<ParsedResponse<DataType>>;
}

export const useMutation = <ArgsType, DataType>({
  method,
}: UseMutationProps<ArgsType, DataType>): UseMutationResult<ArgsType, DataType> => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<DataType>();
  const [error, setError] = React.useState<ResponseError>();

  const invoke = React.useCallback((args: ArgsType) => {
    // always reset state when executing new query
    setLoading(true);
    setError(undefined);
    setData(undefined);

    method(args)
      .then(({ data: _data, error: _error }) => {
        setData(_data);
        setError(_error);
      })
      .catch((err: Error) => setError({ message: err.message }))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) as UseMutationResult<ArgsType, DataType>["invoke"];

  return {
    loading,
    error,
    invoke,
    data,
  };
};
