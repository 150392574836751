import React from "react";
import { useNavigate } from "react-router";

import { ArrowBack, Send } from "@mui/icons-material";

import {
    NewPurchaseOrderBody, NewPurchaseOrderLine, NoteDetails, PurchaseOrderLineDetails,
    PurchaseOrderSummary, ResponseError, UserDetails, UserType
} from "../apiClients/common";
import { createPurchaseOrder, NewNoteBody } from "../apiClients/purchaseOrders";
import { AuthContext } from "../components/AuthContextProvider";
import { ClientCallout } from "../components/ClientCallout";
import { NewNote, PurchaseOrder } from "../components/PurchaseOrder";
import { ContentType, getBase64Str, readFile } from "../helpers/fileHelper";
import { useMutationCall } from "../hooks/useMutationCall";
import { Action } from "../lib/ActionBar";
import { NotificationManagerContext } from "../lib/NotificationManager";
import { NotificationType } from "../lib/NotificationTypes";
import { Page } from "../lib/Page";
import Messages from "../locale/en.json";

enum ActionIds {
  Discard = "Discard",
  Submit = "Submit",
}

export const Order = (): JSX.Element => {
  const auth = React.useContext(AuthContext);
  const notificationManager = React.useContext(NotificationManagerContext);

  const navigate = useNavigate();

  const back = (): void => navigate(-1);

  const [client, setClient] = React.useState<UserDetails | undefined>(
    auth.user?.type === UserType.Client ? auth.user : undefined
  );
  const [purchaseOrderDetails, setPurchaseOrderDetails] = React.useState<NewPurchaseOrderBody>({
    permissionFromOwnerGiven: false,
  });
  const [items, setItems] = React.useState<NewPurchaseOrderLine[]>([]);
  const [notes, setNotes] = React.useState<NewNote[]>([]);
  const [userNotes, setUserNotes] = React.useState<string>();

  const addNote = (note: NewNote): void => {
    setNotes((prevNotes) => [...prevNotes, note]);
  };

  const deleteNote = (note: NewNote | NoteDetails): void => {
    setNotes((prevNotes) => prevNotes.filter((prevNote) => prevNote.key === (note as NewNote).key));
  };

  const [data, setData] = React.useState<PurchaseOrderSummary>();

  const onSuccess = (order?: PurchaseOrderSummary): void => {
    setData(order);
    back();
  };

  const onFailure = (err?: ResponseError): void => {
    if (err) {
      notificationManager.push({
        type: NotificationType.Error,
        title: Messages.errors.createOrder,
        message: err.message,
      });

      if (err.status === 401) {
        auth.logout();
      }
    }
  };

  const { invoke, loading } = useMutationCall({ method: createPurchaseOrder, onFailure, onSuccess });

  const formatNote = async (note: NewNote): Promise<NewNoteBody> => ({
    note: note.note,
    attachments: await Promise.all(
      note.attachments.map(async (attachment) => ({
        name: attachment.name,
        attachment: getBase64Str(await readFile(attachment as File, ContentType.Data)),
        mimetype: attachment.type,
      }))
    ),
  });

  const onSubmit = (): void => {
    Promise.all(notes.map(formatNote)).then((formattedNotes) =>
      invoke({
        details: { ...purchaseOrderDetails, userNotes },
        lines: items,
        notes: formattedNotes,
        client,
      })
    );
  };

  const mainActions: Action[] = [
    {
      id: ActionIds.Discard,
      value: <ArrowBack />,
      onClick: back,
    },
    {
      id: ActionIds.Submit,
      value: <Send />,
      disabled: loading,
      onClick: onSubmit,
    },
  ];

  return (
    <Page actions={mainActions} title={Messages.page.newOrder.title} loading={loading}>
      {!client && <ClientCallout onSubmit={setClient} title={Messages.panels.client.title} onDiscard={back} />}
      {client && (
        <PurchaseOrder
          user={auth.user as UserDetails}
          client={client}
          details={purchaseOrderDetails}
          onDetailsChange={setPurchaseOrderDetails}
          notes={notes.reverse()}
          onAddNote={addNote}
          onDeleteNote={deleteNote}
          items={items}
          // This is okay as we will not have existing po line entries when creating a new PO
          onItemsChange={setItems as (items: (NewPurchaseOrderLine | PurchaseOrderLineDetails)[]) => void}
          status={data?.status}
          onStatusChange={undefined}
          userNotes={userNotes}
          onUserNotesChange={auth.user?.type && auth.user.type !== UserType.Client ? setUserNotes : undefined}
        />
      )}
    </Page>
  );
};
