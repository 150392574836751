import React from "react";

import { ResponseError, UserDetails, UserType } from "../apiClients/common";
import { updateUser, UpdateUserBody } from "../apiClients/users";
import { AuthContext } from "../components/AuthContextProvider";
import { EditUserCallout, EditUserCalloutProps, Fields } from "../components/EditUserCallout";
import { useMutationCall } from "../hooks/useMutationCall";
import { FormValues, getValue } from "../lib/Input/Form";
import { SelectOption } from "../lib/Input/Select";
import { InputFormComponentRef } from "../lib/InputForm/InputForm";

export interface EditUserProps extends Pick<EditUserCalloutProps, "isSelfEdit" | "title" | "onDiscard" | "flow"> {
  userDetails?: UserDetails;
  /**
   * @param user the updated user details
   */
  onExecute: (user?: UserDetails) => void;
}

export const EditUser = ({ userDetails, onExecute, ...rest }: EditUserProps): JSX.Element => {
  const auth = React.useContext(AuthContext);
  const componentRef = React.useRef<InputFormComponentRef>();
  const setComponentRef = (ref: InputFormComponentRef): void => {
    componentRef.current = ref;
  };

  const [error, setError] = React.useState<ResponseError>();
  const onFailure = (err?: ResponseError): void => {
    setError(err);
    if (err?.status === 401) {
      auth.logout();
    } else {
      componentRef.current?.allowResubmit();
    }
  };

  const { invoke } = useMutationCall({ method: updateUser, onFailure, onSuccess: onExecute });

  const onSubmit = (formValues: FormValues): void => {
    const newDetails: UpdateUserBody = {
      company: getValue<string>(formValues, Fields.Company),
      email: getValue<string>(formValues, Fields.Email),
      firstName: getValue<string>(formValues, Fields.FirstName),
      lastName: getValue<string>(formValues, Fields.LastName),
      type: getValue<SelectOption>(formValues, Fields.Type)?.id as UserType | undefined,
      password: getValue<string>(formValues, Fields.Password),
      id: userDetails!.id,
    };
    invoke(newDetails);
  };

  return (
    <EditUserCallout
      componentRef={setComponentRef}
      userDetails={userDetails}
      errors={error?.message ? [error?.message] : undefined}
      onSubmit={onSubmit}
      {...rest}
    />
  );
};
