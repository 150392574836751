import React from "react";

import { UserType } from "../apiClients/common";
import { CompanyDetailsCollection, getCompanies } from "../apiClients/companies";
import { useQueryCall } from "../hooks/useQueryCall";
import { CheckBox } from "../lib/Input/CheckBox";
import { InputType } from "../lib/Input/common";
import { Select, SelectOption } from "../lib/Input/Select";
import { TextInput } from "../lib/Input/TextInput";
import { InputFormCallout, InputFormCalloutProps } from "../lib/InputForm/InputFormCallout";
import Messages from "../locale/en.json";

export enum Fields {
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  ChangeCompanyType = "changeCompanyType",
  NewCompany = "newCompany",
  ExistingCompany = "existingCompany",
  Password = "password",
  ConfirmPassword = "confirmPassword",
  Type = "type",
}

const adminTypeOptions: SelectOption[] = [
  {
    id: UserType.Admin,
    text: Messages.userType.admin,
  },
  {
    id: UserType.SuperAdmin,
    text: Messages.userType.superAdmin,
  },
];

const allUserTypeOptions: SelectOption[] = [
  {
    id: UserType.Client,
    text: Messages.userType.client,
  },
  ...adminTypeOptions,
];

const responseToSelectOptions = (response?: CompanyDetailsCollection): SelectOption[] => {
  if (!response || !response.length) return [];

  const opts: SelectOption[] = response.map((company) => ({
    id: company.id.toString(),
    text: company.name,
    data: company,
  }));

  return opts;
};

export type CreateUserCalloutProps = Omit<InputFormCalloutProps, "submitButtonText" | "discardButtonText" | "children">;

export const CreateUserCallout = (props: CreateUserCalloutProps): JSX.Element => {
  const [roleType, setRoleType] = React.useState<UserType | undefined>();
  const [password, setPassword] = React.useState<string>();
  const [companies, setCompanies] = React.useState<CompanyDetailsCollection>();
  const [createNewCompany, setCreateNewCompany] = React.useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = React.useState<SelectOption>();
  const [companyName, setCompanyName] = React.useState<string>();

  const createdUserIsAdmin = React.useMemo(
    () => roleType === UserType.Admin || roleType === UserType.SuperAdmin,
    [roleType]
  );

  const { loading } = useQueryCall({
    method: getCompanies,
    onSuccess: setCompanies,
  });

  const options = React.useMemo(
    () =>
      responseToSelectOptions(
        companies?.filter((company) => company.name.toLowerCase() !== Messages.cresigns.toLowerCase())
      ),
    [JSON.stringify(companies)]
  );

  const cresignsOption = React.useMemo(() => {
    const cresigns = companies?.find(({ name }) => name.toLowerCase() === Messages.cresigns.toLowerCase());
    const option: SelectOption = {
      id: cresigns?.id.toString() ?? "",
      text: cresigns?.name ?? "",
      data: cresigns,
    };

    return [option];
  }, [JSON.stringify(companies)]);

  return (
    <InputFormCallout {...props}>
      <TextInput required autoComplete="off" fieldName={Fields.FirstName} label={Messages.labels.firstName} />
      <TextInput required autoComplete="off" fieldName={Fields.LastName} label={Messages.labels.lastName} />
      <Select
        required
        options={allUserTypeOptions}
        onChange={(val) => setRoleType(val.id as UserType)}
        fieldName={Fields.Type}
        label={Messages.labels.type}
      />
      {!createdUserIsAdmin && (
        <CheckBox
          key="userCreate"
          label={Messages.labels.createNewCompany}
          fieldName={Fields.ChangeCompanyType}
          defaultValue={createNewCompany}
          onChange={setCreateNewCompany}
        />
      )}
      {!createdUserIsAdmin && createNewCompany ? (
        <TextInput
          required
          autoComplete="off"
          fieldName={Fields.NewCompany}
          label={Messages.labels.company}
          defaultValue={companyName}
          onChange={setCompanyName}
          validator={(val?: string) => {
            if (roleType === UserType.Client) {
              if (val && val.toLocaleLowerCase() === Messages.cresigns.toLocaleLowerCase()) {
                return [Messages.validation.cresigns];
              }
            }
            return undefined;
          }}
        />
      ) : (
        <Select
          search
          required
          loading={loading}
          key={roleType}
          label={Messages.labels.company}
          fieldName={Fields.ExistingCompany}
          disabled={roleType === UserType.Admin || roleType === UserType.SuperAdmin}
          options={roleType === UserType.Admin || roleType === UserType.SuperAdmin ? cresignsOption : options}
          onChange={setSelectedCompany}
          defaultValue={
            roleType === UserType.Admin || roleType === UserType.SuperAdmin
              ? cresignsOption?.[0]?.id
              : selectedCompany?.id
          }
        />
      )}
      <TextInput
        required
        autoComplete="off"
        fieldName={Fields.Email}
        type={InputType.Email}
        label={Messages.labels.email}
      />
      <TextInput
        autoComplete="off"
        fieldName={Fields.Password}
        type={InputType.Password}
        label={Messages.labels.password}
        onChange={setPassword}
      />
      <TextInput
        autoComplete="off"
        key={password}
        fieldName={Fields.ConfirmPassword}
        type={InputType.Password}
        label={Messages.labels.confirmPassword}
        validator={(value?: string) => {
          if (value !== password) {
            return [Messages.validation.confirmPassword];
          }
        }}
      />
    </InputFormCallout>
  );
};
