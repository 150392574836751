import React from "react";

import { Divider, List, Paper } from "@mui/material";

import { ActionBarInternal, ActionBarProps } from "./ActionBar";

export interface DisplayListProps extends Pick<ActionBarProps, "actions"> {
  children: JSX.Element[];
  title?: ActionBarProps["title"];
  ActionBarProps?: Omit<ActionBarProps, "title" | "actions">;
}

export const DisplayList = ({
  children,
  ActionBarProps: actionBarProps,
  actions,
  title,
}: DisplayListProps): JSX.Element => {
  return (
    <Paper elevation={16} style={{ minWidth: "100%", minHeight: children.length ? "min-content" : undefined }}>
      {actions?.length || title ? (
        <div style={{ padding: "2rem" }}>
          <ActionBarInternal actions={actions} title={title ?? ""} {...actionBarProps} />
        </div>
      ) : null}
      <List
        style={{ padding: !children.length ? 0 : "0 2rem 2rem 2rem", display: "flex", flexFlow: "column", gap: "1rem" }}
      >
        {React.Children.map(children, (child, index) => (
          <>
            {child}
            {index < children.length - 1 ? <Divider /> : null}
          </>
        ))}
      </List>
    </Paper>
  );
};
