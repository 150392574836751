import React from "react";

import { OrderStatus } from "../apiClients/common";
import { FlowType } from "../lib/Callout";
import { InputFormCallout, InputFormCalloutProps } from "../lib/InputForm/InputFormCallout";
import Messages from "../locale/en.json";
import { OrderStatusRadioGroup } from "./OrderStatusRadioGroup";

export enum Fields {
  Status = "Status",
}

export interface StatusConfigProps extends Pick<InputFormCalloutProps, "title" | "onDiscard" | "onSubmit"> {
  status: OrderStatus;
}

export const StatusConfig = ({ status, onDiscard, title, onSubmit }: StatusConfigProps): JSX.Element => {
  return (
    <InputFormCallout flow={FlowType.Overlay} title={title} onDiscard={onDiscard} onSubmit={onSubmit}>
      <OrderStatusRadioGroup fieldName={Fields.Status} label={Messages.labels.status} required defaultValue={status} />
    </InputFormCallout>
  );
};
