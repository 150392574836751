import { handleResponse, NoteDetails, ParsedResponse } from "./common";
import { NewNoteBody } from "./purchaseOrders";

export type CreateNoteBody = NewNoteBody & { purchaseOrderId: number };
export type CreateNoteResponse = NoteDetails;

export const createNote = async (body: CreateNoteBody): Promise<ParsedResponse<CreateNoteResponse>> =>
  fetch(`/api/purchaseOrders/${body.purchaseOrderId}/notes`, {
    method: "POST",
    body: JSON.stringify(body),
    credentials: "include",
  }).then(handleResponse<CreateNoteResponse>);

export type DeleteNoteBody = {
  id: number;
};

export type DeleteNoteResponse = NoteDetails;

export const deleteNote = async (body: DeleteNoteBody): Promise<ParsedResponse<DeleteNoteResponse>> =>
  fetch(`/api/notes/${body.id.toString()}`, { method: "DELETE", credentials: "include" }).then(
    handleResponse<DeleteNoteResponse>
  );
