import React from "react";

import { Link } from "@mui/material";

export interface FileLinkProps {
  title: string;
  url: string;
  download?: boolean;
}

export const FileLink = ({ title, url, download }: FileLinkProps): JSX.Element => {
  return (
    <Link target="_blank" underline="none" href={url} download={download ? title : undefined} title={title}>
      {title}
    </Link>
  );
};
