import React from "react";

import { Add as AddIcon, Delete as DeleteIcon, Refresh as RefreshIcon } from "@mui/icons-material";

import { ResponseError, UserDetails, UserType } from "../apiClients/common";
import { deleteUser, getUsers } from "../apiClients/users";
import { AuthContext } from "../components/AuthContextProvider";
import { getUserTypeValue } from "../helpers/resourceHelper";
import { useBulkMutationCall } from "../hooks/useBulkMutationCall";
import { useQueryCall } from "../hooks/useQueryCall";
import { Action } from "../lib/ActionBar";
import { FlowType } from "../lib/Callout";
import { ConfirmDialog } from "../lib/ConfirmDialog";
import { FilterType } from "../lib/Filter";
import { Column, Listing, ListingFilterProps, SelectMode } from "../lib/Listing";
import { NotificationManagerContext } from "../lib/NotificationManager";
import { NotificationType } from "../lib/NotificationTypes";
import { Page } from "../lib/Page";
import Messages from "../locale/en.json";
import { CreateUser } from "../operations/CreateUser";
import { EditUser } from "../operations/EditUser";

enum FilterIds {
  Search = "Search",
  Type = "Type",
}

enum ColumnIds {
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  Company = "company",
  Type = "type",
}

const columns: Column[] = [
  {
    id: ColumnIds.FirstName,
    text: Messages.labels.firstName,
    minWidth: 100,
  },
  {
    id: ColumnIds.LastName,
    text: Messages.labels.lastName,
    minWidth: 100,
  },
  {
    id: ColumnIds.Email,
    text: Messages.labels.email,
    minWidth: 250,
  },
  {
    id: ColumnIds.Company,
    text: Messages.labels.company,
    minWidth: 200,
  },
  {
    id: ColumnIds.Type,
    text: Messages.labels.type,
    minWidth: 150,
    onRender: getUserTypeValue,
  },
];

enum ActionIds {
  Create = "Create",
  Refresh = "Refresh",
  Delete = "Delete",
}

enum PanelIds {
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}

export const Users = (): JSX.Element => {
  const auth = React.useContext(AuthContext);
  const notificationManager = React.useContext(NotificationManagerContext);

  const [users, setUsers] = React.useState<UserDetails[]>();
  const [userType, setUserType] = React.useState<UserType>();
  const [search, setSearch] = React.useState<string>("");

  const { loading, refresh } = useQueryCall({
    method: getUsers,
    args: { search, type: userType },
    onSuccess: setUsers,
    onFailure: (err?: ResponseError) => {
      if (err) {
        if (err?.status === 401) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.loadUsers,
            message: err.message,
          });
        }
      }
    },
  });

  const { loading: deleteLoading, invoke: invokeDelete } = useBulkMutationCall({
    method: deleteUser,
    onSuccess: refresh,
    onFailure: (err?: ResponseError[]) => {
      if (err?.length) {
        if (err?.find((e) => e?.status === 401)) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.deleteUser,
            message: err
              .map((e) => e?.message)
              .filter((message) => !!message)
              .join(" "),
          });
        }
      }
    },
  });

  const [editUser, setEditUser] = React.useState<UserDetails>();
  const [selectedUsers, setSelectedUsers] = React.useState<UserDetails[]>();
  const [openPanel, setOpenPanel] = React.useState<PanelIds>();

  const closePanels = (): void => {
    setOpenPanel(undefined);
  };

  const actions: Action[] = [
    {
      id: ActionIds.Refresh,
      disabled: loading,
      value: <RefreshIcon />,
      onClick: refresh,
    },
    {
      id: ActionIds.Delete,
      value: <DeleteIcon />,
      disabled: selectedUsers?.length !== 1 || deleteLoading,
      onClick: () => setOpenPanel(PanelIds.Delete),
    },
    {
      id: ActionIds.Create,
      value: <AddIcon />,
      onClick: () => setOpenPanel(PanelIds.Create),
    },
  ];

  const getPanel = (): JSX.Element | null => {
    switch (openPanel) {
      case PanelIds.Create:
        return (
          <CreateUser
            flow={FlowType.Overlay}
            title={Messages.common.create}
            onDiscard={closePanels}
            onExecute={() => {
              refresh();
              closePanels();
            }}
          />
        );
      case PanelIds.Update:
        return (
          <EditUser
            flow={FlowType.Overlay}
            title={Messages.common.update}
            userDetails={editUser}
            isSelfEdit={auth.user?.id === editUser?.id}
            onDiscard={closePanels}
            onExecute={() => {
              refresh();
              closePanels();
            }}
          />
        );
      case PanelIds.Delete: {
        let messagePrefix = "";

        if (selectedUsers?.length) {
          if (selectedUsers.length > 1) {
            messagePrefix = Messages.confirm.deleteUsers;
          } else {
            messagePrefix = Messages.confirm.deleteUser;
          }
        }

        const message = messagePrefix + selectedUsers?.map((user) => `${user.firstName} ${user.lastName}`).join(", ");

        return (
          <ConfirmDialog
            message={message}
            onCancel={closePanels}
            onConfirm={() => {
              const userIds = selectedUsers?.map(({ id }) => ({ id })) ?? [];
              invokeDelete(userIds);
              closePanels();
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  const userTypeOptions = [
    { id: undefined, text: Messages.common.all },
    ...Object.values(UserType).map((type) => ({ id: type, text: getUserTypeValue(type) })),
  ];

  const filters = [
    {
      id: FilterIds.Search,
      type: FilterType.Search,
      defaultValue: search,
      onChange: setSearch,
    },
    {
      id: FilterIds.Type,
      type: FilterType.Select,
      label: Messages.labels.type,
      defaultValue: userType,
      onChange: setUserType,
      options: userTypeOptions,
      messageFormatter: getUserTypeValue,
    },
  ];

  return (
    <Page actions={actions} title={Messages.page.users.title} loading={deleteLoading}>
      <Listing
        loading={loading}
        selectionMode={SelectMode.Single}
        onSelect={setSelectedUsers}
        items={users ?? []}
        columns={columns}
        onRowClick={(user: UserDetails) => {
          setEditUser(user);
          setOpenPanel(PanelIds.Update);
        }}
        filters={filters as ListingFilterProps<string | UserType, UserDetails>[]}
      />
      {getPanel()}
    </Page>
  );
};
