import React from "react";
import { v4 as uuid } from "uuid";

import { ResponseError } from "../apiClients/common";
import { getProducts, ProductDetailsCollection } from "../apiClients/products";
import { useQueryCall } from "../hooks/useQueryCall";
import { Select, SelectOption, SelectProps } from "../lib/Input/Select";
import { NotificationManagerContext } from "../lib/NotificationManager";
import { NotificationType } from "../lib/NotificationTypes";
import Messages from "../locale/en.json";
import { AuthContext } from "./AuthContextProvider";

export type ProductSelectProps = Omit<SelectProps, "options">;

export const ProductSelect = (props: ProductSelectProps): JSX.Element => {
  const auth = React.useContext(AuthContext);
  const notificationManager = React.useContext(NotificationManagerContext);
  const [remount, setRemount] = React.useState<string>(uuid());
  const [options, setOptions] = React.useState<SelectOption[]>([]);

  const onLoad = (values: ProductDetailsCollection | undefined): void => {
    setOptions(
      values?.map((product) => ({
        id: product.id.toString(),
        text: product.name,
        data: product,
      })) ?? []
    );
    setRemount(uuid());
  };

  const onError = (err?: ResponseError): void => {
    if (err) {
      if (err?.status === 401) {
        auth.logout();
      } else {
        notificationManager.push({
          type: NotificationType.Error,
          title: Messages.errors.loadProducts,
          message: err.message,
        });
      }
    }
  };

  const { loading } = useQueryCall({
    method: getProducts,
    onSuccess: onLoad,
    onFailure: onError,
  });

  return <Select {...props} loading={loading} options={options} key={remount} />;
};
