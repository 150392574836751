import React from "react";
import { useNavigate } from "react-router";

import { Refresh as RefreshIcon } from "@mui/icons-material";

import {
    NoteDetails, PurchaseOrderSummary, ResponseError, UserDetails, UserType
} from "../apiClients/common";
import { getNotes } from "../apiClients/purchaseOrders";
import { AuthContext } from "../components/AuthContextProvider";
import { OrderPath } from "../constants";
import { DateFormat, formatDate } from "../helpers/dateHelper";
import { useQueryCall } from "../hooks/useQueryCall";
import { Action } from "../lib/ActionBar";
import { FilterType } from "../lib/Filter";
import { Column, Listing, SortOrder } from "../lib/Listing";
import { NotificationManagerContext } from "../lib/NotificationManager";
import { NotificationType } from "../lib/NotificationTypes";
import { Page } from "../lib/Page";
import Messages from "../locale/en.json";

enum ActionIds {
  Create = "Create",
  Refresh = "Refresh",
  Update = "Update",
  Delete = "Delete",
}

enum ColumnIds {
  Created = "createdOn",
  Note = "note",
  Address = "address",
  PurchaseOrder = "purchaseOrderNumber",
  Company = "company",
  User = "name",
  SubmittedBy = "purchaseOrder",
}

const getColumns = (user: UserDetails | undefined): Column[] => {
  const columns: Column[] = [
    {
      id: ColumnIds.Created,
      text: Messages.labels.dateCreated,
      initialSortOrder: SortOrder.Desc,
      onRender: (val: Date) => formatDate(val, DateFormat.ShortDateTime),
    },
    {
      id: ColumnIds.Note,
      text: Messages.labels.note,
    },
    {
      id: ColumnIds.User,
      text: Messages.labels.writtenBy,
    },
    {
      id: ColumnIds.Company,
      text: Messages.labels.company,
    },
    {
      id: ColumnIds.PurchaseOrder,
      text: Messages.labels.purchaseOrderNumber,
    },
  ];

  if (user?.type === UserType.Client) {
    columns.push({
      id: ColumnIds.SubmittedBy,
      text: Messages.labels.purchaseOrderSubmittedBy,
      onRender: (value: PurchaseOrderSummary) => `${value.client.firstName} ${value.client.lastName}`,
    });
  }

  return columns;
};

export enum FilterIds {
  From = "From",
  To = "To",
}

export const Notes = (): JSX.Element => {
  const auth = React.useContext(AuthContext);
  const notificationManager = React.useContext(NotificationManagerContext);

  const navigate = useNavigate();

  const [from, setFrom] = React.useState<Date>(
    new Date(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0))
  );
  const [to, setTo] = React.useState<Date>(new Date(new Date(Date.now()).setHours(23, 59, 59, 999)));

  const onToChange = (newTo: Date): void => {
    setTo(new Date(newTo.setHours(23, 59, 59, 999)));
  };

  const onFromChange = (newFrom: Date): void => {
    setFrom(new Date(newFrom.setHours(0, 0, 0, 0)));
  };

  const [notes, setNotes] = React.useState<NoteDetails[]>();

  const { loading, refresh } = useQueryCall({
    method: getNotes,
    args: { from, to },
    onSuccess: setNotes,
    onFailure: (err?: ResponseError) => {
      if (err) {
        if (err?.status === 401) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.loadNotes,
            message: err.message,
          });
        }
      }
    },
  });

  const actions: Action[] = [
    {
      id: ActionIds.Refresh,
      value: <RefreshIcon />,
      onClick: refresh,
    },
  ];

  const navigateToPO = <T extends NoteDetails>({ purchaseOrder }: T): void => {
    if (purchaseOrder) {
      navigate(`/${OrderPath}/${purchaseOrder?.id}/`);
    }
  };

  const filters = [
    {
      id: FilterIds.From,
      type: FilterType.Date,
      label: Messages.common.from,
      defaultValue: from,
      onChange: onFromChange,
      messageFormatter: (item?: Date) => (item ? formatDate(item, DateFormat.ShortDate) : ""),
    },
    {
      id: FilterIds.To,
      type: FilterType.Date,
      label: Messages.common.to,
      defaultValue: to,
      onChange: onToChange,
      messageFormatter: (item?: Date) => (item ? formatDate(item, DateFormat.ShortDate) : ""),
    },
  ];

  return (
    <Page actions={actions} title={Messages.page.notes.title}>
      <Listing
        loading={loading}
        filters={filters}
        onRowClick={navigateToPO}
        items={
          notes?.map((note) => ({
            ...note,
            [ColumnIds.Address]: note.purchaseOrder?.address,
            [ColumnIds.PurchaseOrder]: note.purchaseOrder?.purchaseOrderNumber,
            [ColumnIds.User]: `${note?.user.firstName} ${note?.user.lastName}`,
            [ColumnIds.Company]: note.user.company,
          })) ?? []
        }
        columns={getColumns(auth.user)}
      />
    </Page>
  );
};
