import React from "react";

import { ProductDetails, UserType } from "../apiClients/common";
import { FlowType } from "../lib/Callout";
import { DecimalInput } from "../lib/Input/DecimalInput";
import { FormValues, getValue } from "../lib/Input/Form";
import { IntegerInput } from "../lib/Input/IntegerInput";
import { ReadOnlyTextInput } from "../lib/Input/ReadOnlyTextInput";
import { SelectOption } from "../lib/Input/Select";
import { SimpleBuilder } from "../lib/Input/SimpleBuilder";
import { InputFormCallout, InputFormCalloutProps } from "../lib/InputForm/InputFormCallout";
import Messages from "../locale/en.json";
import { ProductSelect } from "./ProductSelect";

export enum Fields {
  Items = "Items",
  Item = "product",
  Quantity = "quantity",
  BasePrice = "basePrice",
  Price = "price",
  Total = "Total",
}

export interface PurchaseOrderItemsCalloutProps
  extends Pick<InputFormCalloutProps, "componentRef" | "errors" | "onDiscard" | "onSubmit" | "title"> {
  userType: UserType;
  items: { [Fields.Item]: number | string; [Fields.Price]: string; [Fields.Quantity]: string }[];
}

const getPrice = (priceStr?: string, quantity?: string): string => {
  const price = priceStr ? Number(priceStr) : 0;
  const numItems = quantity ? Number(quantity) : 0;
  const total = !isNaN(numItems) && !isNaN(price) ? price * numItems : 0;
  return total.toFixed(2);
};

const getBasePrice = (item?: SelectOption, quantity?: string): string =>
  getPrice((item?.data as ProductDetails | undefined)?.price, quantity);

export const PurchaseOrderItemsCallout = ({
  items,
  componentRef,
  errors,
  onDiscard,
  onSubmit,
  title,
  userType,
}: PurchaseOrderItemsCalloutProps): JSX.Element => {
  return (
    <InputFormCallout
      flow={FlowType.Overlay}
      width="50rem"
      componentRef={componentRef}
      onDiscard={onDiscard}
      onSubmit={onSubmit}
      errors={errors}
      title={title}
    >
      <SimpleBuilder
        fieldName={Fields.Items}
        defaultValue={items}
        rowModifier={(values: FormValues) => {
          const product = getValue<SelectOption>(values, Fields.Item);
          const quantity = getValue<string>(values, Fields.Quantity);
          const basePrice: string = (product?.data as ProductDetails | undefined)?.price ?? "0";
          const price = getValue<string>(values, Fields.Price);
          const subTotal = getPrice(price || basePrice, quantity);
          return {
            [Fields.Total]: subTotal,
            [Fields.BasePrice]: Number(basePrice).toFixed(2),
          };
        }}
      >
        <ProductSelect fieldName={Fields.Item} label={Messages.labels.item} />
        <IntegerInput fieldName={Fields.Quantity} label={Messages.labels.quantity} min={1} />
        <ReadOnlyTextInput fieldName={Fields.BasePrice} label={Messages.labels.basePrice} defaultValue={getBasePrice()} />
        {userType === UserType.Admin || userType === UserType.SuperAdmin ? (
          <DecimalInput fieldName={Fields.Price} label={Messages.labels.price} />
        ) : (
          // This must be here as the SimpleBuilder cannot handle a non-element currently
          <></>
        )}
        <ReadOnlyTextInput
          fieldName={Fields.Total}
          label={Messages.labels.lineTotal}
          defaultValue={Number(0).toFixed(2)}
        />
      </SimpleBuilder>
    </InputFormCallout>
  );
};
