import React from "react";
import { v4 as uuid } from "uuid";

import { Alert } from "@mui/material";

import { Callout, CalloutProps, FlowType } from "../Callout";
import { InputForm, InputFormProps } from "./InputForm";

export interface InputFormCalloutProps extends Omit<InputFormProps, "onValueUpdate">, Pick<CalloutProps, "flow"> {
  errors?: string[];
  width?: string;
}

export const InputFormCallout = ({
  children,
  errors,
  onDiscard,
  flow,
  width,
  ...rest
}: InputFormCalloutProps): JSX.Element => {
  const [trackedErrors, setTrackedErrors] = React.useState<string[] | undefined>(errors);

  const onValueChanged = (): void => {
    if (trackedErrors?.length) {
      setTrackedErrors(undefined);
    }
  };

  const [key, setKey] = React.useState<string>(uuid());

  const remount = () => {
    setKey(uuid());
  };

  const internalOnDiscard = (): void => {
    remount();
    setTrackedErrors(undefined);
    onDiscard?.();
  };

  React.useEffect(() => {
    setTrackedErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(errors)]);

  return (
    <Callout backdrop={flow === FlowType.Overlay} width={width} flow={flow}>
      <InputForm {...rest} onDiscard={internalOnDiscard} key={key} onValueUpdate={onValueChanged}>
        {children}
        {trackedErrors?.length ? <Alert severity="error">{trackedErrors.join("\n")}</Alert> : null}
      </InputForm>
    </Callout>
  );
};
