import React from "react";

import { TextField, TextFieldProps } from "@mui/material";

import Messages from "../../locale/en.json";
import { InputType } from "./common";
import { FormContext, ValidationMode, ValidationModeContext, ValidationState } from "./Form";

type BaseTextFieldProps = Pick<TextFieldProps, "disabled" | "placeholder" | "required" | "label">;

export interface FileInputProps extends BaseTextFieldProps {
  fieldName: string;
  /**
   * @default 18rem
   */
  width?: React.CSSProperties["width"];
  multiple?: boolean;
  fileTypes?: string[];
  maxSize?: { bytes: number; label: string };
}

export const FileInput = ({
  fieldName,
  label,
  required,
  width = "18rem",
  disabled,
  placeholder,
  multiple,
  fileTypes,
  maxSize,
}: FileInputProps): JSX.Element => {
  const form = React.useContext(FormContext);
  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
  const validationMode = React.useContext(ValidationModeContext);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [files, setFiles] = React.useState<File[]>([]);

  const internalOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.files?.length ? Array.from(e.target.files) : undefined;
    form.setValue(newValue, fieldName);
    setFiles(newValue ?? []);
    if (required || maxSize) {
      fieldValidation();
    }
  };

  const fieldValidation = (): void => {
    const errorMessages: string[] = [];
    const currentValue = form.getValue<File[]>(fieldName);

    if (required && !currentValue?.length) {
      errorMessages.push(Messages.validation.required);
    }

    if (maxSize && currentValue?.length) {
      currentValue.forEach((file) => {
        if (file.size > maxSize.bytes) {
          errorMessages.push(`${Messages.validation.fileSize} ${maxSize.label}`);
        }
      });
    }

    form.setFieldState(errorMessages.length ? ValidationState.INVALID : ValidationState.VALID, fieldName);
    setErrors(errorMessages);
  };

  React.useEffect(() => {
    if (required || maxSize) {
      form.registerField(fieldName);
    }

    return function cleanup() {
      if (required || maxSize) {
        form.unregisterField(fieldName);
      }
      form.deleteField(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (validationMode === ValidationMode.ON) {
      if (required || maxSize) {
        fieldValidation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode]);

  return (
    <>
      <TextField
        id={fieldName}
        label={label}
        style={{ width }}
        name={fieldName}
        onChange={internalOnChange}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        type={InputType.Text}
        value={files.map((file) => file.name).join(", ")}
        onClick={() => inputRef && inputRef.click()}
        InputProps={{
          placeholder: Messages.placeholder.selectFile,
          readOnly: true,
        }}
        error={errors.length > 0}
        helperText={errors.join(" ")}
      />
      <input
        style={{ display: "none" }}
        accept={fileTypes?.join(" ")}
        ref={setInputRef}
        type={InputType.File}
        multiple={multiple}
        onChange={internalOnChange}
      />
    </>
  );
};
