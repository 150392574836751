import React from "react";
import { v4 as uuid } from "uuid";

import { Delete } from "@mui/icons-material";
import { Divider, IconButton, List, ListItem } from "@mui/material";

import Messages from "../../locale/en.json";
import { FormContext, FormState, FormValues, ValidationState } from "./Form";

const buildFieldName = (fieldName: string, rowId: number): string => `${fieldName}-${rowId}`;
const parseFieldName = (fieldName: string): [string, number] => {
  const parts = fieldName.split("-");
  if (parts.length === 2) {
    return [parts[0], Number.parseInt(parts[1], 10)];
  }
  return parts as [string, number];
};

export type OnValueUpdateCallback = (
  formValues: FormValues[],
  value: unknown | undefined,
  rowId: number,
  fieldName: string
) => void;
export type OnStateUpdateCallback = (
  overallState: ValidationState,
  fieldState: ValidationState | undefined,
  rowId: number,
  fieldName: string
) => void;

interface InternalSimpleBuilderState extends FormState {
  getValues: () => FormValues[];
}

class SimpleBuilderState implements InternalSimpleBuilderState {
  private values: Map<number, Record<string, unknown>> = new Map();

  private states: Map<number, Record<string, ValidationState>> = new Map();

  private onValueUpdate?: OnValueUpdateCallback;

  private onStatesUpdate?: OnStateUpdateCallback;

  constructor(_onValueUpdate?: OnValueUpdateCallback, _onStateUpdate?: OnStateUpdateCallback) {
    this.onValueUpdate = _onValueUpdate;
    this.onStatesUpdate = _onStateUpdate;
  }

  registerField = (fieldName: string): void => {
    const [field, rowId] = parseFieldName(fieldName);

    const rowEntry = this.states.get(rowId) ?? {};
    const newEntry = { [field]: ValidationState.UNKNOWN };
    const updatedEntry = Object.assign({}, rowEntry, newEntry);

    this.states.set(rowId, updatedEntry);

    this.onStatesUpdate?.(this.getOverallState(), ValidationState.UNKNOWN, rowId, fieldName);
  };

  unregisterField = (fieldName: string): void => {
    const [field, rowId] = parseFieldName(fieldName);

    const rowEntry = this.states.get(rowId) ?? {};
    delete rowEntry[field];

    this.states.set(rowId, rowEntry);

    this.onStatesUpdate?.(this.getOverallState(), undefined, rowId, field);
  };

  deleteField = (fieldName: string): void => {
    const [field, rowId] = parseFieldName(fieldName);
    const rowValues = this.values.get(rowId) ?? {};
    delete rowValues[field];
    this.values.set(rowId, rowValues);
    this.onValueUpdate?.(Array.from(this.values.values()), undefined, rowId, field);
  };

  getValue = <ValueType,>(fieldName: string): ValueType | undefined => {
    const [field, rowId] = parseFieldName(fieldName);
    const rowValues = this.values.get(rowId) ?? {};
    return rowValues[field] as ValueType | undefined;
  };

  setValue = (value: unknown, fieldName: string): void => {
    const [field, rowId] = parseFieldName(fieldName);
    const rowValues = this.values.get(rowId) ?? {};
    rowValues[field] = value;
    this.values.set(rowId, rowValues);
    this.onValueUpdate?.(Array.from(this.values.values()), undefined, rowId, field);
  };

  setFieldState = (validationState: ValidationState, fieldName: string): void => {
    const [field, rowId] = parseFieldName(fieldName);

    const rowEntry = this.states.get(rowId) ?? {};
    const newEntry = { [field]: validationState };
    const updatedEntry = Object.assign({}, rowEntry, newEntry);

    this.states.set(rowId, updatedEntry);

    this.onStatesUpdate?.(this.getOverallState(), validationState, rowId, fieldName);
  };

  getFieldState = (fieldName: string): ValidationState | undefined => {
    const [field, rowId] = parseFieldName(fieldName);
    const rowStates = this.states.get(rowId) ?? {};
    return rowStates[field];
  };

  getValues = (): FormValues[] => Array.from(this.values.values());

  getOverallState = (): ValidationState => {
    const states = Array.from(this.states.values()).map((rowStates) => Object.values(rowStates));
    // we always have a template row currently so this will need to be fixed
    states.pop();
    const flatStates = states.flat(1);
    if (flatStates.some((state) => state === ValidationState.UNKNOWN)) return ValidationState.UNKNOWN;
    if (flatStates.every((state) => state === ValidationState.VALID)) return ValidationState.VALID;
    return ValidationState.INVALID;
  };
}

interface SimpleBuilderStateContextProviderProps {
  children?: JSX.Element;
  componentRef?: (ref: InternalSimpleBuilderState) => void;
  onValueUpdate?: OnValueUpdateCallback;
  onStatesUpdate?: OnStateUpdateCallback;
}

const SimpleBuilderStateContextProvider = ({
  componentRef,
  children,
  onStatesUpdate,
  onValueUpdate,
}: SimpleBuilderStateContextProviderProps): JSX.Element => {
  const formState = React.useMemo<InternalSimpleBuilderState>(
    () => new SimpleBuilderState(onValueUpdate, onStatesUpdate),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    componentRef?.({ ...formState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef]);

  return <FormContext.Provider value={{ ...formState }}>{children}</FormContext.Provider>;
};

export interface SimpleBuilderProps {
  children: React.ReactNode;
  fieldName: string;
  defaultValue?: FormValues[];
  required?: boolean;
  onChange?: (values?: FormValues[]) => void;
  rowModifier?: (values: FormValues) => FormValues;
  validator?: (values?: FormValues[]) => string[] | undefined;
}

export const SimpleBuilder = ({
  children,
  fieldName,
  defaultValue,
  onChange,
  rowModifier,
  required,
}: SimpleBuilderProps): JSX.Element => {
  const formState = React.useContext(FormContext);
  const fieldNameSet = React.useMemo(() => {
    const fieldNames = React.Children.map(children, (child) => (child as React.ReactElement).props.fieldName as string);
    return Array.from(new Set(fieldNames));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultRows = React.useMemo(() => (defaultValue?.length ?? 0) + 1, []);
  const [rowIds, setRowIds] = React.useState<number[]>(new Array(defaultRows).fill(undefined).map((_, index) => index));
  const [nextRowId, setNextRowId] = React.useState(rowIds.length + 1);
  const [, rerender] = React.useState(uuid());
  const simpleBuilderStateRef = React.useRef<InternalSimpleBuilderState>();
  const setSimpleBuilderState = (ref: InternalSimpleBuilderState): void => {
    simpleBuilderStateRef.current = ref;
  };
  const [, setErrors] = React.useState<string[]>();

  const managedKeys: Map<number, Record<string, string>> = React.useMemo(() => new Map(), []);

  const setManagedKey = (rowId: number, field: string): void => {
    const currentKeys = managedKeys.get(rowId) ?? {};
    const updatedKeys = { ...currentKeys, [field]: uuid() };
    managedKeys.set(rowId, updatedKeys);
  };

  const managedValues: Map<number, FormValues> = React.useMemo(() => new Map(), []);

  const setManagedValue = (rowId: number, field: string, value: unknown): void => {
    const currentValues = managedValues.get(rowId) ?? {};
    const updatedValues = { ...currentValues, [field]: value };
    managedValues.set(rowId, updatedValues);
  };

  const onStateUpdate: OnStateUpdateCallback = (
    overallState: ValidationState
    // fieldState: ValidationState | undefined,
    // rowId: number,
    // field: string
  ): void => {
    formState.setFieldState(overallState, fieldName);
  };

  const onValueUpdate: OnValueUpdateCallback = (
    values: FormValues[],
    _: unknown,
    rowId: number
    // field: string
  ): void => {
    const transformedValues = transformValues(values);
    formState.setValue(transformedValues, fieldName);
    fieldValidation();
    onChange?.(transformedValues);
    modifyRow(rowId);
  };

  const modifyRow = (id: number): void => {
    if (!rowModifier) return;

    const rowValues: FormValues = {};
    fieldNameSet.forEach((field) => {
      rowValues[field] = simpleBuilderStateRef.current?.getValue(buildFieldName(field, id));
    });

    const updatedRow = rowModifier(rowValues);
    const updatedRowEntries = Object.entries(updatedRow);

    updatedRowEntries.forEach(([field, value]) => {
      setManagedKey(id, field);
      setManagedValue(id, field, value);
    });

    if (updatedRowEntries.length) {
      rerender(uuid());
    }
  };

  const transformValues = (values?: FormValues[]): FormValues[] | undefined => {
    const rows: FormValues[] = [];
    values?.forEach((value) => {
      const row: FormValues = {};
      const entries = Object.entries(value);
      entries.forEach(([key, val]) => {
        // eslint-disable-next-line eqeqeq
        if (val != undefined && val !== "") {
          row[key] = val;
        }
      });

      if (Object.keys(row).length) {
        rows.push(row);
      }
    });

    return rows.length ? rows : undefined;
  };

  const fieldValidation = (): void => {
    const values = formState.getValue<FormValues[]>(fieldName);
    if (required && !values?.length) {
      setErrors([Messages.validation.required]);
    }
  };

  React.useEffect(() => {
    if (required) {
      formState.registerField(fieldName);
    }

    if (defaultValue?.length) {
      formState.setValue(defaultValue, fieldName);
      if (required) {
        fieldValidation();
      }
    }

    rowIds.forEach(modifyRow);

    return function cleanup() {
      if (required) {
        formState.unregisterField(fieldName);
      }
      formState.deleteField(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteRow = (id: number): void => {
    fieldNameSet.forEach((field) => {
      simpleBuilderStateRef.current?.deleteField(buildFieldName(field, id));
    });
    setRowIds((ids) => {
      const newIds = ids.filter((_id) => _id !== id);
      return newIds;
    });
  };

  const rowOnChange = (id: number): void => {
    if (id === rowIds[rowIds.length - 1]) {
      setRowIds([...rowIds, nextRowId]);
      setNextRowId(nextRowId + 1);
    }
  };

  return (
    <SimpleBuilderStateContextProvider
      onStatesUpdate={onStateUpdate}
      onValueUpdate={onValueUpdate}
      componentRef={setSimpleBuilderState}
    >
      <List style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        <Divider />
        {rowIds.map((id, index) => {
          const isInitialPosition = id === index;
          const defaultRowValues = isInitialPosition ? defaultValue?.[index] ?? {} : {};
          const rowDefaultValue = { ...defaultRowValues, ...(managedValues.get(id) ?? {}) };

          return (
            <React.Fragment key={id}>
              <Row
                id={id}
                keys={managedKeys.get(id)}
                defaultValue={rowDefaultValue}
                onDelete={index !== rowIds.length - 1 ? () => deleteRow(id) : undefined}
                onChange={() => rowOnChange(id)}
              >
                {children}
              </Row>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    </SimpleBuilderStateContextProvider>
  );
};

interface RowProps {
  children: React.ReactNode;
  defaultValue?: FormValues;
  keys?: Record<string, string>;
  id: number;
  onChange?: () => void;
  onDelete?: () => void;
}

const Row = ({ children, id, defaultValue, onChange, onDelete, keys }: RowProps): JSX.Element => {
  return (
    <ListItem sx={{ display: "flex", gap: "1rem", alignItems: "flex-start" }}>
      {React.Children.map(children, (child) => {
        const element = child as React.ReactElement;
        const field = buildFieldName(element.props.fieldName, id);

        return element ? (
          React.cloneElement(element, {
            ...element.props,
            key: keys?.length ? keys[field] : field,
            fieldName: field,
            defaultValue: defaultValue?.[element.props.fieldName] ?? element.props.defaultValue,
            onChange: (val: unknown) => {
              element.props.onChange?.(val);
              onChange?.();
            },
          })
        ) : (
          <React.Fragment />
        );
      })}
      <IconButton onClick={onDelete} style={{ visibility: onDelete ? "visible" : "hidden" }}>
        <Delete />
      </IconButton>
    </ListItem>
  );
};
