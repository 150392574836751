import React from "react";
import { v4 as uuid } from "uuid";

import { identity } from "../apiClients/auth";
import { ResponseError, UserDetails } from "../apiClients/common";
import { useMutationCall } from "../hooks/useMutationCall";
import { Loading } from "./Loading";

export interface AppState {
  user: UserDetails | undefined;
  isAuthenticated: () => boolean;
  login: (user: UserDetails) => void;
  setUser: (user?: UserDetails) => void;
  logout: () => void;
}

export const AuthContext = React.createContext<AppState>({} as AppState);

export interface AuthContextProviderProps {
  children: JSX.Element;
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps): JSX.Element => {
  const [, setKey] = React.useState<string>(uuid());
  const rerender = (): void => setKey(uuid());

  const user = React.useRef<UserDetails>();

  const login = (loginUser: UserDetails): void => {
    user.current = loginUser;
    rerender();
  };

  const logout = (): void => {
    user.current = undefined;
    window.location.href = "/";
    rerender();
  };

  const isAuthenticated = (): boolean => {
    return !!user.current?.id;
  };

  const setUser = (userDetails?: UserDetails): void => {
    user.current = userDetails;
    if (!user) {
      logout();
    }
    rerender();
  };

  const onFailure = (err?: ResponseError): void => {
    console.error(err?.message);
    user.current = undefined;
  };

  const { invoke, loading } = useMutationCall({ method: identity, onSuccess: setUser, onFailure });

  React.useEffect(invoke, []);

  const authContext = React.useMemo(
    () => ({ login, logout, isAuthenticated, user: user.current, setUser }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(user.current)]
  );

  return <AuthContext.Provider value={authContext}>{loading ? <Loading /> : children}</AuthContext.Provider>;
};
