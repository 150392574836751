import React from "react";

import { ResponseError, UserDetails, UserType } from "../apiClients/common";
import { createUser, CreateUserBody } from "../apiClients/users";
import { AuthContext } from "../components/AuthContextProvider";
import { CreateUserCallout, CreateUserCalloutProps, Fields } from "../components/CreateUserCallout";
import { useMutationCall } from "../hooks/useMutationCall";
import { FormValues, getValue } from "../lib/Input/Form";
import { SelectOption } from "../lib/Input/Select";
import { InputFormComponentRef } from "../lib/InputForm/InputForm";

export interface CreateUserProps extends Pick<CreateUserCalloutProps, "title" | "onDiscard" | "flow"> {
  /**
   * @param user the updated user details
   */
  onExecute: (user?: UserDetails) => void;
}

export const CreateUser = ({ onExecute, ...rest }: CreateUserProps): JSX.Element => {
  const auth = React.useContext(AuthContext);
  const componentRef = React.useRef<InputFormComponentRef>();
  const setComponentRef = (ref: InputFormComponentRef): void => {
    componentRef.current = ref;
  };

  const [error, setError] = React.useState<ResponseError>();
  const onFailure = (err?: ResponseError): void => {
    setError(err);
    if (err?.status === 401) {
      auth.logout();
    } else {
      componentRef.current?.allowResubmit();
    }
  };

  const { invoke } = useMutationCall({ method: createUser, onFailure, onSuccess: onExecute });

  const onSubmit = (formValues: FormValues): void => {
    const newDetails: CreateUserBody = {
      companyId: getValue<SelectOption>(formValues, Fields.ExistingCompany)
        ? Number(getValue<SelectOption>(formValues, Fields.ExistingCompany)?.id)
        : undefined,
      company:
        getValue<string>(formValues, Fields.NewCompany) ||
        getValue<SelectOption>(formValues, Fields.ExistingCompany)?.text ||
        "",
      email: getValue<string>(formValues, Fields.Email) || "",
      firstName: getValue<string>(formValues, Fields.FirstName) || "",
      lastName: getValue<string>(formValues, Fields.LastName) || "",
      type: getValue<SelectOption>(formValues, Fields.Type)?.id as UserType,
      password: getValue<string>(formValues, Fields.Password) || "",
    };
    invoke(newDetails);
  };

  return (
    <CreateUserCallout
      componentRef={setComponentRef}
      errors={error?.message ? [error?.message] : undefined}
      onSubmit={onSubmit}
      {...rest}
    />
  );
};
