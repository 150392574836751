import React from "react";

import Messages from "../locale/en.json";

export enum ResourceType {
  Product = "Product",
  PurchaseOrder = "PurchaseOrder",
  User = "User",
}
export interface NotFoundProps {
  resourceType: ResourceType;
}

const getResourceTypeLabel = (type: ResourceType): string => {
  switch (type) {
    case ResourceType.Product:
      return Messages.resourceType.product;
    case ResourceType.PurchaseOrder:
      return Messages.resourceType.purchaseOrder;
    case ResourceType.User:
      return Messages.resourceType.user;
    default:
      return type;
  }
};

export const NotFound = ({ resourceType }: NotFoundProps): JSX.Element => {
  return (
    <div style={{ display: "flex", flexFlow: "column", alignItems: "center", justifyContent: "center", gap: "2rem" }}>
      <div>
        <img alt="404 not found" src="/404.png" />
      </div>
      <p>
        {getResourceTypeLabel(resourceType)} {Messages.page.notFound.title}
      </p>
    </div>
  );
};
