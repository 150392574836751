import {
  buildUrl,
  handleResponse,
  NewPurchaseOrderBody,
  NewPurchaseOrderLine,
  NoteDetails,
  OrderStatus,
  ParsedResponse,
  PurchaseOrderDetails,
  PurchaseOrderLineDetails,
  PurchaseOrderSummary,
  UpdatePurchaseOrderBody as UpdatePurchaseOrder,
  UserDetails,
} from "./common";

export type PurchaseOrderDetailsCollection = PurchaseOrderSummary[];

export type GetPurchaseOrdersResponse = PurchaseOrderDetailsCollection;
export type GetPurchaseOrdersReqQuery = { from?: Date; to?: Date; search?: string; status?: OrderStatus };
export type GetPurchaseOrdersBody = GetPurchaseOrdersReqQuery;

export const getPurchaseOrders = async (
  body: GetPurchaseOrdersBody | undefined
): Promise<ParsedResponse<GetPurchaseOrdersResponse>> => {
  const query = Object.fromEntries(
    Object.entries(body ?? {}).map(([key, value]) => [key, typeof value === "string" ? value : value.toString()])
  );

  return fetch(buildUrl("/api/purchaseOrders", query), {
    method: "GET",
    credentials: "include",
  }).then(handleResponse<GetPurchaseOrdersResponse>);
};

export interface FindPurchaseOrdersByIdBody {
  id: number;
}

export type FindPurchaseOrdersByIdResponse = PurchaseOrderDetails;

export const findPurchaseOrdersById = async (
  body: FindPurchaseOrdersByIdBody | undefined
): Promise<ParsedResponse<FindPurchaseOrdersByIdResponse>> =>
  fetch(`/api/purchaseOrders/${body?.id}`, {
    method: "GET",
    credentials: "include",
  }).then(handleResponse<FindPurchaseOrdersByIdResponse>);

export interface DeletePurchaseOrderBody {
  id: number;
}

export type DeletePurchaseOrderResponse = PurchaseOrderSummary;

export const deletePurchaseOrder = async (
  body: DeletePurchaseOrderBody
): Promise<ParsedResponse<DeletePurchaseOrderResponse>> =>
  fetch(`/api/purchaseOrders/${body.id}`, {
    method: "DELETE",
    credentials: "include",
  }).then(handleResponse<DeletePurchaseOrderResponse>);

export type UpdatePurchaseOrderDetailsBody = UpdatePurchaseOrder & { id: number };
export type UpdatePurchaseOrderDetailsResponse = UpdatePurchaseOrder & { id: number };

export const updatePurchaseOrderDetails = async (
  body: UpdatePurchaseOrderDetailsBody
): Promise<ParsedResponse<UpdatePurchaseOrderDetailsResponse>> =>
  fetch(`/api/purchaseOrders/${body?.id}/details`, {
    method: "PUT",
    body: JSON.stringify(body),
    credentials: "include",
  }).then(handleResponse<UpdatePurchaseOrderDetailsResponse>);

export type UpdatePurchaseOrderItemsBody = { id: number; lines: NewPurchaseOrderLine[] };
export type UpdatePurchaseOrderItemsResponse = PurchaseOrderLineDetails;

export const updatePurchaseOrderItems = async (
  body: UpdatePurchaseOrderItemsBody
): Promise<ParsedResponse<UpdatePurchaseOrderItemsResponse>> =>
  fetch(`/api/purchaseOrders/${body?.id}/items`, {
    method: "PUT",
    body: JSON.stringify(body),
    credentials: "include",
  }).then(handleResponse<UpdatePurchaseOrderItemsResponse>);

export type UpdatePurchaseOrderBody = {
  id: number;
  details?: UpdatePurchaseOrder;
  lines?: (PurchaseOrderLineDetails | NewPurchaseOrderLine)[];
  notes?: (NoteDetails | NewNoteBody)[];
};
export type UpdatePurchaseOrderResponse = PurchaseOrderSummary;

export const updatePurchaseOrder = async (
  body: UpdatePurchaseOrderBody
): Promise<ParsedResponse<UpdatePurchaseOrderResponse>> =>
  fetch(`/api/purchaseOrders/${body?.id}`, {
    method: "PUT",
    body: JSON.stringify(body),
    credentials: "include",
  }).then(handleResponse<UpdatePurchaseOrderResponse>);

type NewAttachment = { name: string; attachment: string; mimetype: string };
export type NewNoteBody = Pick<NoteDetails, "note"> & { attachments: NewAttachment[] };

export interface CreatePurchaseOrderBody {
  details: NewPurchaseOrderBody;
  lines?: NewPurchaseOrderLine[];
  notes?: NewNoteBody[];
  client?: UserDetails;
}

export type CreatePurchaseOrderResponse = PurchaseOrderSummary;

export const createPurchaseOrder = async (
  body: CreatePurchaseOrderBody
): Promise<ParsedResponse<CreatePurchaseOrderResponse>> =>
  fetch("/api/purchaseOrders", {
    method: "POST",
    body: JSON.stringify(body),
    credentials: "include",
  }).then(handleResponse<CreatePurchaseOrderResponse>);

export type UpdatePurchaseOrderStatusBody = { id: number; status: OrderStatus };
export type UpdatePurchaseOrderStatusResponse = PurchaseOrderDetails;

export const updatePurchaseOrderStatus = async (
  body: UpdatePurchaseOrderStatusBody
): Promise<ParsedResponse<UpdatePurchaseOrderStatusResponse>> =>
  fetch(`/api/purchaseOrders/${body?.id}/status`, {
    method: "PUT",
    body: JSON.stringify(body),
    credentials: "include",
  }).then(handleResponse<UpdatePurchaseOrderStatusResponse>);

export type GetNotesResponse = Array<NoteDetails>;
type GetNotesReqQuery = { from?: Date; to?: Date };

export const getNotes = async (body?: GetNotesReqQuery): Promise<ParsedResponse<GetNotesResponse>> => {
  const query = Object.fromEntries(
    Object.entries(body ?? {}).map(([key, value]) => [key, typeof value === "string" ? value : value.toString()])
  );

  return fetch(buildUrl("/api/notes", query), {
    method: "GET",
    credentials: "include",
  }).then(handleResponse<GetNotesResponse>);
};

export type UpdatePurchaseOrderUserNotesBody = { id: number } & Pick<PurchaseOrderSummary, "userNotes">;
export type UpdatePurchaseOrderUserNotesResponse = PurchaseOrderDetails;

export const updatePurchaseOrderUserNotes = async (
  body: UpdatePurchaseOrderUserNotesBody
): Promise<ParsedResponse<UpdatePurchaseOrderUserNotesResponse>> =>
  fetch(`/api/purchaseOrders/${body?.id}/userNotes`, {
    method: "PUT",
    body: JSON.stringify(body),
    credentials: "include",
  }).then(handleResponse<UpdatePurchaseOrderUserNotesResponse>);
