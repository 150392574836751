import React from "react";

import { useQuery, UseQueryProps, UseQueryResult } from "./useQuery";

export interface UseQueryCallProps<DataType, ArgsType> extends UseQueryProps<DataType, ArgsType> {
  onSuccess?: (response: UseQueryResult<DataType>["data"]) => void;
  onFailure?: (error: UseQueryResult<DataType>["error"]) => void;
}

export type UseQueryCallResult<DataType> = Pick<UseQueryResult<DataType>, "refresh" | "loading">;

export const useQueryCall = <DataType, ArgsType>({
  onFailure,
  onSuccess,
  args,
  method,
}: UseQueryCallProps<DataType, ArgsType>): UseQueryCallResult<DataType> => {
  const { loading, data, error, refresh } = useQuery({ args, method });

  React.useEffect(() => {
    if (!loading) {
      if (error) {
        onFailure?.(error);
      } else {
        onSuccess?.(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), JSON.stringify(error), loading]);

  return {
    loading,
    refresh,
  };
};
