import React from "react";

import { ProductDetails, ResponseError } from "../apiClients/common";
import { updateProduct, UpdateProductBody } from "../apiClients/products";
import { AuthContext } from "../components/AuthContextProvider";
import { Fields, ProductConfigCallout, ProductConfigCalloutProps } from "../components/ProductConfigCallout";
import { useMutationCall } from "../hooks/useMutationCall";
import { FormValues, getValue } from "../lib/Input/Form";
import { InputFormComponentRef } from "../lib/InputForm/InputForm";

export interface EditProductProps extends Pick<ProductConfigCalloutProps, "title" | "onDiscard"> {
  productDetails?: ProductDetails;
  /**
   * @param product the created product details
   */
  onExecute: (product?: ProductDetails) => void;
}

export const EditProduct = ({ productDetails, onExecute, ...rest }: EditProductProps): JSX.Element => {
  const auth = React.useContext(AuthContext);
  const componentRef = React.useRef<InputFormComponentRef>();
  const setComponentRef = (ref: InputFormComponentRef): void => {
    componentRef.current = ref;
  };

  const [error, setError] = React.useState<ResponseError>();
  const onFailure = (err?: ResponseError): void => {
    setError(err);
    if (err?.status === 401) {
      auth.logout();
    } else {
      componentRef.current?.allowResubmit();
    }
  };

  const { invoke } = useMutationCall({ method: updateProduct, onFailure, onSuccess: onExecute });

  const onSubmit = (formValues: FormValues): void => {
    const newDetails: UpdateProductBody = {
      id: productDetails!.id,
      name: getValue<string>(formValues, Fields.Name),
      price: getValue<string>(formValues, Fields.Price),
    };
    invoke(newDetails);
  };

  return (
    <ProductConfigCallout
      componentRef={setComponentRef}
      productDetails={productDetails}
      errors={error?.message ? [error?.message] : undefined}
      onSubmit={onSubmit}
      {...rest}
    />
  );
};
