import React from "react";
import { createBrowserRouter, Outlet, RouteObject, RouterProvider } from "react-router-dom";

import { AuthContext } from "./components/AuthContextProvider";
import { Nav } from "./components/Nav";
import { PageId, PageRegistrationConfig } from "./constants";
import { NotificationManagerProvider } from "./lib/NotificationManager";
import { NotificationOutlet } from "./lib/NotificationOutlet";
import { NotificationInfo } from "./lib/NotificationTypes";
import { ActiveOrders } from "./pages/ActiveOrders";
import { CompletedOrders } from "./pages/CompletedOrders";
import { Order } from "./pages/CreateNewOrder";
import { EditOrder } from "./pages/EditOrder";
import { Error } from "./pages/Error";
import { Login } from "./pages/Login";
import { NewOrders } from "./pages/NewOrders";
import { Notes } from "./pages/Notes";
import { Products } from "./pages/Products";
import { Users } from "./pages/Users";

const authRoutes: RouteObject[] = [
  {
    path: PageRegistrationConfig[PageId.Notes].path,
    Component: Notes,
  },
  {
    path: PageRegistrationConfig[PageId.NewOrders].path,
    Component: NewOrders,
  },
  {
    path: PageRegistrationConfig[PageId.ActiveOrders].path,
    Component: ActiveOrders,
  },
  {
    path: PageRegistrationConfig[PageId.CompletedOrders].path,
    Component: CompletedOrders,
  },
  {
    path: PageRegistrationConfig[PageId.Order].path,
    Component: Order,
  },
  {
    path: PageRegistrationConfig[PageId.EditOrder].path,
    Component: EditOrder,
  },
  {
    path: PageRegistrationConfig[PageId.Products].path,
    Component: Products,
  },
  {
    path: PageRegistrationConfig[PageId.Users].path,
    Component: Users,
  },
  {
    path: "/",
    Component: Notes,
  },
  {
    path: "*",
    Component: Notes,
  },
];

export const Console = (): JSX.Element => {
  const [notifications, setNotifications] = React.useState<NotificationInfo[]>([]);

  return (
    <>
      <Nav />
      <NotificationManagerProvider onNotificationsChanged={setNotifications}>
        <Outlet />
      </NotificationManagerProvider>
      <NotificationOutlet notifications={notifications} />
    </>
  );
};

const consoleRoute = (routes: RouteObject[]): RouteObject[] => [
  {
    path: "/",
    Component: () => <Console />,
    errorElement: <Error />,
    children: routes,
  },
];

const consoleRouter = createBrowserRouter(consoleRoute(authRoutes));

const App = (): JSX.Element => {
  const auth = React.useContext(AuthContext);
  return auth.isAuthenticated() ? <RouterProvider router={consoleRouter} /> : <Login />;
};

export default App;
