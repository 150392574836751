import React from "react";
import { useNavigate } from "react-router";

import { logout } from "../apiClients/auth";
import { UserDetails, UserType } from "../apiClients/common";
import { PageId, PageRegistrationConfig } from "../constants";
import { useMutationCall } from "../hooks/useMutationCall";
import { FlowType } from "../lib/Callout";
import Messages from "../locale/en.json";
import { EditUser } from "../operations/EditUser";
import { AuthContext } from "./AuthContextProvider";
import { TopMenu, TopMenuItem } from "./TopMenu";

enum NavItems {
  Notes = "Notes",
  NewOrders = "NewOrders",
  ActiveOrders = "ActiveOrders",
  CompletedOrders = "CompletedOrders",
  Products = "Products",
  Profile = "Profile",
  Users = "Users",
  Logout = "Logout",
}

const clientItems = (navigate?: (path: string) => void): TopMenuItem[] => [
  {
    id: NavItems.Notes,
    text: Messages.page.notes.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.Notes].path);
    },
  },
  {
    id: NavItems.NewOrders,
    text: Messages.page.newOrders.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.NewOrders].path);
    },
  },
  {
    id: NavItems.ActiveOrders,
    text: Messages.page.activeOrders.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.ActiveOrders].path);
    },
  },
  {
    id: NavItems.CompletedOrders,
    text: Messages.page.completedOrders.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.CompletedOrders].path);
    },
  },
];

const adminItems = (navigate?: (path: string) => void): TopMenuItem[] => [
  // OpenOrders should be displayed at the activity route
  {
    id: NavItems.Notes,
    text: Messages.page.notes.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.Notes].path);
    },
  },
  {
    id: NavItems.NewOrders,
    text: Messages.page.newOrders.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.NewOrders].path);
    },
  },
  {
    id: NavItems.ActiveOrders,
    text: Messages.page.activeOrders.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.ActiveOrders].path);
    },
  },
  {
    id: NavItems.CompletedOrders,
    text: Messages.page.completedOrders.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.CompletedOrders].path);
    },
  },
];

const superAdminItems = (navigate?: (path: string) => void): TopMenuItem[] => [
  {
    id: NavItems.Notes,
    text: Messages.page.notes.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.Notes].path);
    },
  },
  {
    id: NavItems.NewOrders,
    text: Messages.page.newOrders.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.NewOrders].path);
    },
  },
  {
    id: NavItems.ActiveOrders,
    text: Messages.page.activeOrders.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.ActiveOrders].path);
    },
  },
  {
    id: NavItems.CompletedOrders,
    text: Messages.page.completedOrders.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.CompletedOrders].path);
    },
  },
  {
    id: NavItems.Products,
    text: Messages.page.products.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.Products].path);
    },
  },
  {
    id: NavItems.Users,
    text: Messages.page.users.title,
    onClick: () => {
      navigate?.(PageRegistrationConfig[PageId.Users].path);
    },
  },
];

const getMenuItems = (role: UserType | undefined, navigate?: (path: string) => void): TopMenuItem[] => {
  switch (role) {
    case UserType.Client:
      return clientItems(navigate);
    case UserType.Admin:
      return adminItems(navigate);
    case UserType.SuperAdmin:
      return superAdminItems(navigate);
    default:
      return [];
  }
};

enum PanelId {
  Profile = "Profile",
}

export const Nav = (): JSX.Element => {
  const auth = React.useContext(AuthContext);
  const navigate = useNavigate();

  const [panel, setPanel] = React.useState<PanelId>();
  const closePanels = (): void => setPanel(undefined);
  const openProfilePanel = () => setPanel(PanelId.Profile);

  const { invoke } = useMutationCall({
    method: logout,
    onSuccess: auth.logout,
    onFailure: auth.logout,
  });

  const logoutMenuItem = { id: NavItems.Logout, text: Messages.common.logout, onClick: invoke };

  const menuItems: TopMenuItem[] = auth.isAuthenticated()
    ? [...getMenuItems(auth.user?.type, navigate), logoutMenuItem]
    : [];

  const onUserUpdate = (user?: UserDetails): void => {
    auth.setUser(user);
    closePanels();
  };

  const getPanel = (): JSX.Element | null => {
    switch (panel) {
      case PanelId.Profile:
        return (
          <EditUser
            onDiscard={closePanels}
            onExecute={onUserUpdate}
            userDetails={auth.user}
            title={Messages.page.profile.title}
            isSelfEdit
            flow={FlowType.Overlay}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TopMenu title={Messages.cresigns} items={menuItems} onProfileClick={openProfilePanel} user={auth.user} />
      {getPanel()}
    </>
  );
};
