import dayjs from "dayjs";
import React, { ChangeEvent } from "react";

import { Chip, List, ListItem, Popover, TextField } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";

import Messages from "../locale/en.json";
import { SelectOption } from "./Input/Select";

export enum FilterType {
  Date = "Date",
  Search = "Search",
  Select = "Select",
  //   DateTime = "DateTime",
}

// who cares what the other types are, except for the filterable type
export interface FilterProps<FilterValueType, ItemType extends Record<string, any | FilterValueType>> {
  label?: string;
  defaultValue?: FilterValueType;
  messageFormatter?: (filterValue?: FilterValueType) => string;
  type: FilterType;
  options?: SelectOption[];
  filter?: (filterValue: FilterValueType) => (rowItem: ItemType) => boolean;
  onChange?: (filterValue: FilterValueType) => void;
}

export function Filter<DefaultValueType, ItemType extends Record<string, unknown | DefaultValueType>>({
  defaultValue,
  label,
  type,
  filter,
  messageFormatter,
  onChange,
  options,
}: FilterProps<DefaultValueType, ItemType>): JSX.Element {
  if (!filter && !onChange) throw new Error("Filter must be used with either onChange or filter");

  const pillRef = React.useRef<PillWrapperComponentRef>();
  const setPillRef = (ref: PillWrapperComponentRef): void => {
    pillRef.current = ref;
  };

  const [value, setValue] = React.useState<DefaultValueType | undefined>(defaultValue);

  const Input = React.useMemo<(props: BaseFilterProps<DefaultValueType>) => JSX.Element>(() => {
    switch (type) {
      case FilterType.Date:
        return DateFilter as unknown as (props: BaseFilterProps<DefaultValueType>) => JSX.Element;
      case FilterType.Search:
        return SearchFilter as unknown as (props: BaseFilterProps<DefaultValueType>) => JSX.Element;
      case FilterType.Select:
        // actually accepts SelectFilterProps
        return SelectFilter as unknown as (props: BaseFilterProps<DefaultValueType>) => JSX.Element;
    }
  }, []);

  const onFilterValueChange = (val: DefaultValueType): void => {
    setValue(val);
    onChange?.(val);
    pillRef.current?.close();
  };

  const internalLabel = React.useMemo(() => {
    const val = messageFormatter?.(value) || value?.toString() || Messages.common.all;

    return (
      <span>
        <strong>{label}</strong>: {val}
      </span>
    );
  }, [label, value]);

  if (type === FilterType.Search) {
    return <Input onChange={onFilterValueChange} value={value} />;
  }

  if (type === FilterType.Date) {
    return (
      <PillWrapper
        componentRef={setPillRef}
        label={internalLabel}
        filter={<Input onChange={onFilterValueChange} value={value} />}
      />
    );
  }

  if (options?.length && type === FilterType.Select) {
    const Select: typeof SelectFilter<DefaultValueType> = Input;

    return (
      <PillWrapper
        componentRef={setPillRef}
        label={internalLabel}
        filter={<Select options={options} onChange={onFilterValueChange} value={value} />}
      />
    );
  }

  return <></>;
}

interface PillWrapperComponentRef {
  close: () => void;
}

interface PillWrapperProps {
  componentRef?: (ref: PillWrapperComponentRef) => void;
  label: string | JSX.Element;
  filter: JSX.Element;
}

const PillWrapper = ({ label, filter, componentRef }: PillWrapperProps): JSX.Element => {
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
  const [displayFilter, setDisplayFilter] = React.useState<boolean>(false);

  const openFilter = (): void => setDisplayFilter(true);
  const closeFilter = (): void => setDisplayFilter(false);

  const onChipClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchor(e.currentTarget);
    openFilter();
  };

  React.useEffect(() => {
    if (componentRef) {
      componentRef({ close: closeFilter });
    }
  }, []);

  return (
    <>
      <Chip label={label} onClick={onChipClick} variant="filled" />
      <Popover
        open={displayFilter}
        onClose={closeFilter}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {filter}
      </Popover>
    </>
  );
};

interface BaseFilterProps<ValueType> {
  value: ValueType | undefined;
  onChange: (value: ValueType) => void;
}

type DateFilterProps = BaseFilterProps<Date>;

const DateFilter = ({ value, onChange }: DateFilterProps): JSX.Element => {
  const internalOnChange = (val: dayjs.Dayjs | null): void => {
    onChange(val?.toDate() ?? new Date(Date.now()));
  };

  return <DateCalendar onChange={internalOnChange} value={dayjs(value)} />;
};

type SearchFilterProps = BaseFilterProps<string>;

const SearchFilter = ({ value, onChange }: SearchFilterProps): JSX.Element => {
  const internalOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e.currentTarget.value);
  };

  return <TextField size="small" value={value} onChange={internalOnChange} label={Messages.labels.search} />;
};

interface SelectFilterProps<T> extends BaseFilterProps<T> {
  options: SelectOption[];
}

const SelectFilter = <T,>({ onChange, options, value }: SelectFilterProps<T>): JSX.Element => {
  return (
    <List>
      {options.map(({ id, text }) => (
        <ListItem
          key={id}
          onClick={() => onChange(id as T)}
          style={{ backgroundColor: value === id ? "#f3f2f1" : undefined, cursor: "pointer" }}
        >
          {text}
        </ListItem>
      ))}
    </List>
  );
};
