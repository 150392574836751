import React from "react";

import { login, LoginArgs } from "../apiClients/auth";
import { ResponseError, UserDetails } from "../apiClients/common";
import { AuthContext } from "../components/AuthContextProvider";
import { TopMenu } from "../components/TopMenu";
import { useMutationCall } from "../hooks/useMutationCall";
import { FlowType } from "../lib/Callout";
import { InputType } from "../lib/Input/common";
import { FormValues } from "../lib/Input/Form";
import { TextInput } from "../lib/Input/TextInput";
import { InputFormComponentRef } from "../lib/InputForm/InputForm";
import { InputFormCallout } from "../lib/InputForm/InputFormCallout";
import { Page } from "../lib/Page";
import Messages from "../locale/en.json";

enum Fields {
  Email = "email",
  Password = "password",
}

export const Login = (): JSX.Element => {
  const app = React.useContext(AuthContext);

  const componentRef = React.useRef<InputFormComponentRef>();
  const setComponentRef = (ref: InputFormComponentRef): void => {
    componentRef.current = ref;
  };

  const [error, setError] = React.useState<ResponseError>();

  const { invoke } = useMutationCall({
    method: login,
    onFailure: (err?: ResponseError) => {
      setError(err);
      componentRef.current?.allowResubmit();
    },
    onSuccess: (data?: UserDetails) => {
      if (data) {
        app.login(data);
      }
    },
  });

  const onSubmit = (values: FormValues): void => {
    invoke(values as unknown as LoginArgs);
  };

  return (
    <>
      <TopMenu title={Messages.cresigns} />
      <Page title="">
        <InputFormCallout
          flow={FlowType.Overlay}
          componentRef={setComponentRef}
          onSubmit={onSubmit}
          title={Messages.panels.login.title}
          errors={error?.message ? [error?.message] : undefined}
        >
          <TextInput required fieldName={Fields.Email} type={InputType.Email} label={Messages.labels.email} />
          <TextInput required type={InputType.Password} fieldName={Fields.Password} label={Messages.labels.password} />
        </InputFormCallout>
      </Page>
    </>
  );
};
