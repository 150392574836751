import React from "react";
import { useNavigate, useParams } from "react-router";

import { ArrowBack, Refresh as RefreshIcon } from "@mui/icons-material";

import {
  NewPurchaseOrderBody,
  NewPurchaseOrderLine,
  NoteDetails,
  OrderStatus,
  PurchaseOrderDetails,
  PurchaseOrderSummary,
  ResponseError,
  UserDetails,
  UserType,
} from "../apiClients/common";
import { createNote, deleteNote } from "../apiClients/notes";
import {
  findPurchaseOrdersById,
  updatePurchaseOrderDetails,
  UpdatePurchaseOrderDetailsBody,
  updatePurchaseOrderItems,
  updatePurchaseOrderStatus,
  updatePurchaseOrderUserNotes,
} from "../apiClients/purchaseOrders";
import { AuthContext } from "../components/AuthContextProvider";
import { NewNote, PurchaseOrder } from "../components/PurchaseOrder";
import { ContentType, getBase64Str, readFile } from "../helpers/fileHelper";
import { useMutationCall } from "../hooks/useMutationCall";
import { useQueryCall } from "../hooks/useQueryCall";
import { Action } from "../lib/ActionBar";
import { NotificationManagerContext } from "../lib/NotificationManager";
import { NotificationType } from "../lib/NotificationTypes";
import { Page } from "../lib/Page";
import Messages from "../locale/en.json";
import { NotFound, ResourceType } from "./NotFound";

enum ActionIds {
  Back = "Back",
  Refresh = "Refresh",
}

// Each group submit from a client should update the record in the database when it is a new order
// when it is a processing or printing order, only notes should be editable by user
// when it is completed, notes added during previous states should be hidden, notes added after completino should be shown
export const EditOrder = () => {
  const auth = React.useContext(AuthContext);
  const notificationManager = React.useContext(NotificationManagerContext);
  const params = useParams();
  const id = Number(params.id);
  const navigate = useNavigate();

  const back = (): void => navigate(-1);

  const [notFound, setNotFound] = React.useState<boolean>(false);
  const { loading, refresh } = useQueryCall({
    method: findPurchaseOrdersById,
    args: { id },
    onFailure: (err?: ResponseError) => {
      if (err) {
        if (err.status === 401) {
          auth.logout();
        } else if (err.status === 404) {
          setNotFound(true);
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.loadOrder,
            message: err.message,
          });
        }
      }
    },
    onSuccess: (data?: PurchaseOrderDetails) => {
      setDetails(data?.details);
      setItems(data?.items ?? []);
      setNotes(data?.notes ?? []);
    },
  });

  const { invoke: invokeCreateNote, loading: createNoteLoading } = useMutationCall({
    method: createNote,
    onFailure: (err?: ResponseError) => {
      if (err) {
        if (err.status === 401) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.createNote,
            message: err.message,
          });
        }
      }
    },
    onSuccess: refresh,
  });

  const { invoke: invokeDeleteNote, loading: deleteNoteLoading } = useMutationCall({
    method: deleteNote,
    onFailure: (err?: ResponseError) => {
      if (err) {
        if (err?.status === 401) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.deleteNote,
            message: err.message,
          });
        }
      }
    },
    onSuccess: refresh,
  });

  const { invoke: invokeUpdateDetails, loading: updateDetailsLoading } = useMutationCall({
    method: updatePurchaseOrderDetails,
    onFailure: (err?: ResponseError) => {
      if (err) {
        if (err?.status === 401) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.updateDetails,
            message: err.message,
          });
        }
      }
    },
    onSuccess: refresh,
  });

  const { invoke: invokeUpdateItems, loading: updateItemsLoading } = useMutationCall({
    method: updatePurchaseOrderItems,
    onFailure: (err?: ResponseError) => {
      if (err) {
        if (err.status === 401) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.updateItems,
            message: err.message,
          });
        }
      }
    },
    onSuccess: refresh,
  });

  const { invoke: invokeUpdateStatus, loading: updateStatusLoading } = useMutationCall({
    method: updatePurchaseOrderStatus,
    onFailure: (err?: ResponseError) => {
      if (err) {
        if (err.status === 401) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.updateStatus,
            message: err.message,
          });
        }
      }
    },
    onSuccess: refresh,
  });

  const { invoke: invokeUpdateUserNotes, loading: updateUserNotesLoading } = useMutationCall({
    method: updatePurchaseOrderUserNotes,
    onFailure: (err?: ResponseError) => {
      if (err) {
        if (err.status === 401) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.updateUserNotes,
            message: err.message,
          });
        }
      }
    },
    onSuccess: refresh,
  });

  const mainActions: Action[] = [
    {
      id: ActionIds.Refresh,
      value: <RefreshIcon />,
      onClick: refresh,
    },
    {
      id: ActionIds.Back,
      value: <ArrowBack />,
      onClick: back,
    },
  ];

  const [details, setDetails] = React.useState<PurchaseOrderSummary>();
  const [items, setItems] = React.useState<(NewPurchaseOrderLine & { id?: number })[]>([]);
  const [notes, setNotes] = React.useState<(NoteDetails | NewNote)[]>([]);

  const onDetailsChange = (newDetails: UpdatePurchaseOrderDetailsBody | NewPurchaseOrderBody): void => {
    invokeUpdateDetails({
      ...details,
      ...(newDetails as UpdatePurchaseOrderDetailsBody),
    });
  };

  const onItemsChange = (newItems: (NewPurchaseOrderLine & { id?: number })[]): void => {
    invokeUpdateItems({ lines: newItems, id: details?.id as number });
  };

  const addNote = (note: NewNote): void => {
    Promise.resolve().then(async () => {
      invokeCreateNote({
        note: note.note,
        purchaseOrderId: Number(id),
        attachments: await Promise.all(
          note.attachments.map(async (attachment) => ({
            attachment: getBase64Str(await readFile(attachment, ContentType.Data)),
            name: attachment.name,
            mimetype: attachment.type,
          }))
        ),
      });
    });
  };

  const deleteNewNote = (note: NewNote): void => {
    setNotes((prevNotes) => prevNotes.filter((prev) => (prev as NewNote).key !== note.key));
  };

  const onDeleteNote = (note: NewNote | NoteDetails): void => {
    if ((note as NoteDetails).id) {
      invokeDeleteNote(note as NoteDetails);
    } else if ((note as NewNote).key) {
      deleteNewNote(note as NewNote);
    }
  };

  const onStatusChange = (status: OrderStatus): void => {
    invokeUpdateStatus({ id, status });
  };

  const onUserNotesChange = (userNotes: string): void => {
    invokeUpdateUserNotes({ id, userNotes });
  };

  if (notFound) return <NotFound resourceType={ResourceType.PurchaseOrder} />;

  return (
    <Page
      actions={mainActions}
      title={Messages.page.editOrder.title}
      loading={
        loading ||
        createNoteLoading ||
        deleteNoteLoading ||
        updateDetailsLoading ||
        updateItemsLoading ||
        updateStatusLoading ||
        updateUserNotesLoading
      }
    >
      <PurchaseOrder
        user={auth.user as UserDetails}
        details={details}
        // only unsubmitted POs may have their details modified
        onDetailsChange={!details?.status ? onDetailsChange : undefined}
        notes={notes}
        onAddNote={addNote}
        onDeleteNote={onDeleteNote}
        items={items}
        onItemsChange={
          // Clients cannot edit the items of a submitted order as otherwise there is a conflicting source of truth and potential race condition between client and admin pricing
          (auth.user?.type === UserType.Admin || auth.user?.type === UserType.SuperAdmin) &&
          details?.status !== OrderStatus.Complete
            ? onItemsChange
            : undefined
        }
        status={details?.status}
        onStatusChange={auth.user?.type && auth.user.type !== UserType.Client ? onStatusChange : undefined}
        userNotes={details?.userNotes}
        onUserNotesChange={auth.user?.type && auth.user.type !== UserType.Client ? onUserNotesChange : undefined}
      />
    </Page>
  );
};
