import React from "react";

import { FlowType } from "../lib/Callout";
import { FileInput } from "../lib/Input/FileInput";
import { TextInput } from "../lib/Input/TextInput";
import { InputFormCallout, InputFormCalloutProps } from "../lib/InputForm/InputFormCallout";
import Messages from "../locale/en.json";

export enum Fields {
  Note = "Note",
  Attachments = "Attachments",
}

export type CreateNoteProps = Pick<InputFormCalloutProps, "componentRef" | "errors" | "title" | "onSubmit" | "onDiscard">;

export const CreateNote = (props: CreateNoteProps): JSX.Element => {
  return (
    <InputFormCallout {...props} flow={FlowType.Overlay}>
      <TextInput
        autoComplete="off"
        fieldName={Fields.Note}
        label={Messages.labels.note}
        required
        multiline={{ minRows: 4 }}
      />
      <FileInput
        fieldName={Fields.Attachments}
        label={Messages.labels.attachments}
        fileTypes={["image/*", "application/pdf"]}
        maxSize={{
          bytes: 10485760,
          label: "10MB",
        }}
        multiple
      />
    </InputFormCallout>
  );
};
