export enum NotificationType {
  Error = "error",
  Info = "info",
  Success = "success",
  Warning = "warning",
}

export interface NotificationInfo {
  key: string;
  title: string;
  message: string;
  type: NotificationType | undefined;
  onClose?: () => void;
}
