import React from "react";

import { ParsedResponse, ResponseError } from "../apiClients/common";

export interface UseQueryResult<DataType> extends ParsedResponse<DataType> {
  loading: boolean;
  refresh: () => void;
}

export interface UseQueryProps<DataType, ArgsType> {
  method: (args?: ArgsType) => Promise<ParsedResponse<DataType>>;
  args?: ArgsType;
}

export const useQuery = <DataType, ArgsType>({
  method,
  args,
}: UseQueryProps<DataType, ArgsType>): UseQueryResult<DataType> => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<DataType>();
  const [error, setError] = React.useState<ResponseError>();

  const refresh = React.useCallback(() => {
    // always reset state when executing new query
    setLoading(true);
    setError(undefined);
    setData(undefined);

    method(args)
      .then(({ data: _data, error: _error }) => {
        setData(_data);
        setError(_error);
      })
      .catch((err: Error) => setError({ message: err.message }))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(args)]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(refresh, [JSON.stringify(args)]);

  return {
    loading,
    error,
    data,
    refresh,
  };
};
