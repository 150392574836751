import React from "react";

import { Alert, AlertTitle, Snackbar, SnackbarContent } from "@mui/material";

import { NotificationInfo } from "./NotificationTypes";

export type NotificationProps = Omit<NotificationInfo, "key">;

export const Notification = ({ type, title, message, onClose }: NotificationProps): JSX.Element => {
  let content: JSX.Element = <SnackbarContent message={message} />;

  if (type) {
    content = (
      <Alert severity={type} onClose={onClose} sx={{ width: "100%" }}>
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        {message}
      </Alert>
    );
  }

  return (
    <Snackbar open onClose={onClose}>
      {content}
    </Snackbar>
  );
};
