import React from "react";

import { Box, Divider, List, ListItem, ListItemText, Paper } from "@mui/material";

import { ActionBarInternal, ActionBarProps } from "./ActionBar";

export interface MetaItem {
  id: string;
  title: string;
  description?: string;
}

interface MetaItemSectionDisplayProps {
  items: MetaItem[];
}

const MetaItemSectionDisplay = ({ items }: MetaItemSectionDisplayProps): JSX.Element => (
  <List sx={{ flexGrow: 1, minWidth: "15rem" }}>
    {items.map((item) => (
      <React.Fragment key={item.id}>
        <ListItem>
          <ListItemText
            sx={{ display: "flex", justifyContent: "space-between", marginTop: "4px", marginBottom: "4px" }}
            primary={item.title}
            secondary={item.description}
            secondaryTypographyProps={{ style: { lineHeight: "1.75" } }}
          />
        </ListItem>
        <Divider component="li" />
      </React.Fragment>
    ))}
  </List>
);

export type MetaItemSection = MetaItem[];

export interface MetaItemDisplayProps extends Pick<ActionBarProps, "actions"> {
  sections: MetaItemSection[];
  title?: ActionBarProps["title"];
  ActionBarProps?: Omit<ActionBarProps, "title" | "actions">;
}

export const MetaItemDisplay = ({
  sections,
  actions,
  title,
  ActionBarProps: actionBarProps,
}: MetaItemDisplayProps): JSX.Element => (
  <Paper elevation={16} style={{ overflow: "auto" }}>
    {actions?.length || title ? (
      <div style={{ padding: "2rem 2rem 0 2rem" }}>
        <ActionBarInternal actions={actions} title={title ?? ""} {...actionBarProps} />
      </div>
    ) : null}
    <Box
      sx={{
        boxSizing: "border-box",
        padding: "0 2rem 2rem 2rem",
        display: "flex",
        gap: "1rem",
        justifyContent: "space-between",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      {sections.map((section, index) => (
        <MetaItemSectionDisplay items={section} key={index} />
      ))}
    </Box>
  </Paper>
);
