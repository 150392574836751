import React from "react";

import { alpha, Paper, Skeleton, styled, Typography } from "@mui/material";
import { DataGrid, gridClasses, GridRenderCellParams, GridValueFormatterParams } from "@mui/x-data-grid";

import Messages from "../locale/en.json";
import { ActionBarInternal, ActionBarProps } from "./ActionBar";
import { Filter, FilterProps } from "./Filter";
import { ListingCell } from "./ListingCell";

export interface HasId {
  id: string | number;
}

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export interface Column {
  /**
   * the property that will be indexed from the item(s)
   */
  id: string;
  text: string;
  description?: string;
  minWidth?: number;
  initialSortOrder?: SortOrder;
  /**
   * Format the given value for each row
   */
  onRender?: (value: any) => string;
}

export interface ListingFilterProps<FilterValueType, ItemType extends Record<string, any | FilterValueType>>
  extends FilterProps<FilterValueType, ItemType> {
  id: string;
}

const filterContainerStyle: React.CSSProperties = {
  padding: "1rem",
  display: "flex",
  flexFlow: "row",
  gap: "1rem",
  flexWrap: "wrap",
  alignItems: "center",
};

export enum SelectMode {
  Multiple = "Multiple",
  Single = "Single",
  None = "None",
}

export interface ListingProps<FilterValueType, ItemType extends HasId & Record<string, any | FilterValueType>>
  extends Pick<ActionBarProps, "actions"> {
  loading?: boolean;
  items: ItemType[];
  columns: Column[];
  filters?: ListingFilterProps<FilterValueType, ItemType>[];
  /**
   * @default None
   */
  selectionMode?: SelectMode;
  onSelect?: (items: ItemType[]) => void;
  onRowClick?: (item: ItemType) => void;
  title?: ActionBarProps["title"];
  ActionBarProps?: Omit<ActionBarProps, "title" | "actions">;
}

export const Listing = <FilterValueType, ItemType extends HasId & Record<string, any | FilterValueType>>({
  loading,
  columns,
  filters,
  items,
  selectionMode = SelectMode.None,
  onSelect,
  onRowClick,
  actions,
  title,
  ActionBarProps: actionBarProps,
}: ListingProps<FilterValueType, ItemType>): JSX.Element => {
  const defaultSorting = React.useMemo(
    () => columns.filter((col) => !!col.initialSortOrder).map((col) => ({ field: col.id, sort: col.initialSortOrder })),
    [columns, JSON.stringify(columns)]
  );

  const selection = React.useRef<(string | number)[]>([]);

  const setSelection = React.useCallback(
    (ids: (number | string)[]) => {
      if (selectionMode === SelectMode.None) return;

      let result: (string | number)[] = ids;

      if (selectionMode === SelectMode.Single) {
        if (ids.length > 1) {
          const selectedIds = new Set(selection.current);
          result = result.filter((id) => !selectedIds.has(id));
        }
      }

      selection.current = result;
      onSelect?.(result.map((id) => items.find((item) => item.id === id)) as ItemType[]);
    },
    [items]
  );

  return (
    <Paper
      elevation={16}
      style={{ minWidth: "100%", minHeight: items.length ? "min-content" : "18rem", overflow: "hidden" }}
    >
      {actions?.length || title ? (
        <div style={{ padding: items.length ? "2rem" : "2rem 2rem 0 2rem" }}>
          <ActionBarInternal actions={actions} title={title ?? ""} {...actionBarProps} />
        </div>
      ) : null}
      {!items.length && loading && <LoadingList />}
      {filters?.length ? (
        <div style={filterContainerStyle}>{filters?.map((filter) => <Filter key={filter.id} {...filter} />)}</div>
      ) : null}
      {!items.length && !loading && <EmptyList />}
      {!!items.length && (
        <StripedDataGrid
          initialState={{
            sorting: {
              sortModel: defaultSorting,
            },
          }}
          loading={loading}
          columns={columns.map((col) => ({
            field: col.id,
            headerName: col.text,
            description: col.description || col.text,
            minWidth: col.minWidth,
            flex: 1,
            type: "string",
            renderCell: (params: GridRenderCellParams) => <ListingCell {...params} />,
            valueFormatter: (params: GridValueFormatterParams<string>): string =>
              col.onRender?.(params.value) ?? params.value,
          }))}
          rows={items}
          onRowClick={(e) => onRowClick?.(e.row)}
          checkboxSelection={selectionMode !== SelectMode.None}
          disableRowSelectionOnClick={!!onRowClick || !onSelect}
          onRowSelectionModelChange={setSelection}
          rowSelectionModel={selection.current}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
        />
      )}
    </Paper>
  );
};

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const LoadingList = (): JSX.Element => (
  <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
    <Skeleton animation="wave" variant="rounded" width="100%" height="2rem" />
    <Skeleton animation="wave" variant="rounded" width="100%" height="2rem" />
    <Skeleton animation="wave" variant="rounded" width="100%" height="2rem" />
    <Skeleton animation="wave" variant="rounded" width="100%" height="2rem" />
  </div>
);

const EmptyList = (): JSX.Element => (
  <div
    style={{
      padding: "2rem",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "1rem",
    }}
  >
    <img alt="empty list" src="/emptyListCropped.png" />
    <Typography variant="body1" style={{ fontSize: "2rem", color: "#757575" }}>
      {Messages.common.noResults}
    </Typography>
  </div>
);
