import React from "react";

import Messages from "../../locale/en.json";
import { TextInput, TextInputProps } from "./TextInput";

export interface DecimalInputProps extends TextInputProps {
  /**
   * The max number of decimal places allowed
   * @default 2
   */
  maxDecimalPlaces?: number;
  /**
   * The minimum number of decimal places allowed
   * @default 0
   */
  minDecimalPlaces?: number;
}

const decimalRegex = /^\d*(\.\d*)?$/;

export const DecimalInput = ({
  validator,
  required,
  maxDecimalPlaces = 2,
  minDecimalPlaces = 0,
  ...rest
}: DecimalInputProps): JSX.Element => {
  const internalValidator = (value?: string): string[] | undefined => {
    const errors = validator?.(value) ?? [];

    if (value && !decimalRegex.test(value)) {
      errors.push(Messages.validation.decimalInvalid);
    }

    const vals = value?.split(".");
    const decimals = vals?.length === 2 ? vals[1] : "";

    if (decimals.length > maxDecimalPlaces) {
      errors.push(Messages.validation.maxDecimalPlaces + maxDecimalPlaces);
    }

    if (decimals.length < minDecimalPlaces) {
      errors.push(Messages.validation.minDecimalPlaces + minDecimalPlaces);
    }

    return errors;
  };

  return <TextInput validator={internalValidator} required={required} {...rest} />;
};
