import { buildUrl, handleResponse, ParsedResponse, UserDetails, UserType } from "./common";

export type UserDetailsCollection = UserDetails[];

export type GetUsersResponse = UserDetailsCollection;

type GetUserReqQuery = { type?: UserType; search?: string };

export const getUsers = async (query?: GetUserReqQuery): Promise<ParsedResponse<GetUsersResponse>> => {
  return fetch(buildUrl("/api/users", query), {
    method: "GET",
    credentials: "include",
  }).then(handleResponse<GetUsersResponse>);
};

export interface FindUserByIdBody {
  id: number;
}

export type FindUserByIdResponse = UserDetails;

export const findUserById = async (body: FindUserByIdBody): Promise<ParsedResponse<FindUserByIdResponse>> =>
  fetch(`/api/users/${body.id}`, {
    method: "GET",
    credentials: "include",
  }).then(handleResponse<FindUserByIdResponse>);

export interface DeleteUserBody {
  id: number;
}

export type DeleteUserResponse = UserDetails;

export const deleteUser = async (body: DeleteUserBody): Promise<ParsedResponse<DeleteUserResponse>> =>
  fetch(`/api/users/${body.id}`, {
    method: "DELETE",
    credentials: "include",
  }).then(handleResponse<DeleteUserResponse>);

// all fields should be optional (though at least one should be required, and updating the password should be allowed as well)
export type UpdateUserBody = Pick<UserDetails, "id"> &
  Partial<Omit<UserDetails, "id" | "createdOn" | "updatedOn">> & { password?: string };
export type UpdateUserResponse = UserDetails;

export const updateUser = async (body: UpdateUserBody): Promise<ParsedResponse<UpdateUserResponse>> =>
  fetch(`/api/users/${body.id}`, {
    method: "PUT",
    body: JSON.stringify(body),
    credentials: "include",
  }).then(handleResponse<UpdateUserResponse>);

export type CreateUserBody = Omit<UserDetails, "id" | "createdOn" | "updatedOn"> & {
  password: string;
  companyId?: number;
};
export type CreateUserResponse = UserDetails;

export const createUser = async (body: CreateUserBody): Promise<ParsedResponse<CreateUserResponse>> =>
  fetch("/api/users/", {
    method: "POST",
    body: JSON.stringify(body),
    credentials: "include",
  }).then(handleResponse<CreateUserResponse>);
