import React from "react";

import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";

import Messages from "../../locale/en.json";
import { FormContext, ValidationMode, ValidationModeContext, ValidationState } from "./Form";

export interface CheckBoxProps {
  fieldName: string;
  defaultValue?: boolean;
  onChange?: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
  required?: boolean;
}

export const CheckBox = ({
  fieldName,
  defaultValue,
  label,
  onChange,
  disabled,
  required,
}: CheckBoxProps): JSX.Element => {
  const form = React.useContext(FormContext);
  const validationMode = React.useContext(ValidationModeContext);

  const [errors, setErrors] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (required) {
      form.registerField(fieldName);
    }

    form.setValue(!!defaultValue, fieldName);

    if (required && !defaultValue) {
      fieldValidation();
    }

    return function cleanup() {
      if (required) {
        form.unregisterField(fieldName);
      }
      form.deleteField(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (validationMode === ValidationMode.ON) {
      if (required) {
        fieldValidation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode]);

  const fieldValidation = (): void => {
    const errorMessages: string[] = [];
    const currentValue = form.getValue<string>(fieldName);
    if (required && !currentValue) {
      errorMessages.push(Messages.validation.required);
    }
    form.setFieldState(errorMessages.length ? ValidationState.INVALID : ValidationState.VALID, fieldName);
    setErrors(errorMessages);
  };

  const internalOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.currentTarget.checked;
    form.setValue(newValue, fieldName);
    onChange?.(newValue);
    if (required) {
      fieldValidation();
    }
  };

  return (
    <FormControl error={errors.length > 0}>
      <FormControlLabel
        label={label}
        required={required}
        disabled={disabled}
        control={<Checkbox onChange={internalOnChange} defaultChecked={defaultValue} />}
      />
      {errors.length > 0 && <FormHelperText>{errors.join(" ")}</FormHelperText>}
    </FormControl>
  );
};
