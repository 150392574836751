import React from "react";
import { v4 as uuid } from "uuid";

import { TextField, TextFieldProps } from "@mui/material";

import { InputType } from "./common";

export type MultilineConfig = Pick<TextFieldProps, "rows" | "maxRows" | "minRows">;

type BaseTextFieldProps = Pick<TextFieldProps, "disabled" | "placeholder" | "required" | "label">;

export interface ReadOnlyTextInputProps extends BaseTextFieldProps {
  fieldName: string;
  multiline?: MultilineConfig;
  defaultValue?: string;
  /**
   * @default 18rem
   */
  width?: React.CSSProperties["width"];
}

export const ReadOnlyTextInput = ({
  label,
  fieldName,
  defaultValue,
  disabled,
  multiline,
  placeholder,
  required,
  width = "18rem",
}: ReadOnlyTextInputProps): JSX.Element => {
  const [key, setKey] = React.useState<string>(uuid());

  React.useEffect(() => {
    setKey(uuid());
  }, [defaultValue]);

  return (
    <TextField
      key={key}
      id={fieldName}
      label={label}
      style={{ width }}
      name={fieldName}
      multiline={!!multiline}
      rows={multiline?.rows}
      minRows={multiline?.minRows}
      maxRows={multiline?.maxRows}
      defaultValue={defaultValue}
      disabled={disabled}
      required={required}
      InputProps={{
        readOnly: true,
      }}
      placeholder={placeholder}
      type={InputType.Text}
    />
  );
};
