import React from "react";

import { UserDetails, UserType } from "../apiClients/common";
import { InputType } from "../lib/Input/common";
import { Select, SelectOption } from "../lib/Input/Select";
import { TextInput } from "../lib/Input/TextInput";
import { InputFormCallout, InputFormCalloutProps } from "../lib/InputForm/InputFormCallout";
import Messages from "../locale/en.json";

export enum Fields {
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  Company = "company",
  Password = "password",
  ConfirmPassword = "confirmPassword",
  Type = "type",
}

const adminTypeOptions: SelectOption[] = [
  {
    id: UserType.Admin,
    text: Messages.userType.admin,
  },
  {
    id: UserType.SuperAdmin,
    text: Messages.userType.superAdmin,
  },
];

const userTypeOptions: SelectOption[] = [
  {
    id: UserType.Client,
    text: Messages.userType.client,
  },
];

export interface UpdateUserDetails extends Omit<UserDetails, "id"> {
  password?: string;
}

export interface EditUserCalloutProps
  extends Omit<InputFormCalloutProps, "submitButtonText" | "discardButtonText" | "children"> {
  isSelfEdit?: boolean;
  userDetails?: UpdateUserDetails;
}

export const EditUserCallout = ({ userDetails, isSelfEdit, ...rest }: EditUserCalloutProps): JSX.Element => {
  const [roleType, setRoleType] = React.useState<UserType | undefined>(userDetails?.type);
  const [password, setPassword] = React.useState<string>();
  const remountConfirmPassword = password;

  return (
    <InputFormCallout {...rest}>
      <TextInput
        required
        autoComplete="off"
        fieldName={Fields.FirstName}
        defaultValue={userDetails?.firstName}
        label={Messages.labels.firstName}
      />
      <TextInput
        required
        autoComplete="off"
        fieldName={Fields.LastName}
        defaultValue={userDetails?.lastName}
        label={Messages.labels.lastName}
      />
      <Select
        required
        options={
          userDetails?.type === UserType.Admin || userDetails?.type === UserType.SuperAdmin
            ? adminTypeOptions
            : userTypeOptions
        }
        onChange={(val) => setRoleType(val.id as UserType)}
        defaultValue={userDetails?.type}
        fieldName={Fields.Type}
        disabled={isSelfEdit || userDetails?.type === UserType.Client}
        label={Messages.labels.type}
      />
      <TextInput
        disabled
        required
        key={roleType}
        autoComplete="off"
        fieldName={Fields.Company}
        label={Messages.labels.company}
        defaultValue={userDetails?.company}
      />
      <TextInput
        required
        autoComplete="off"
        fieldName={Fields.Email}
        type={InputType.Email}
        defaultValue={userDetails?.email}
        label={Messages.labels.email}
      />
      <TextInput
        autoComplete="off"
        fieldName={Fields.Password}
        type={InputType.Password}
        label={Messages.labels.password}
        onChange={setPassword}
      />
      <TextInput
        autoComplete="off"
        key={remountConfirmPassword}
        fieldName={Fields.ConfirmPassword}
        type={InputType.Password}
        label={Messages.labels.confirmPassword}
        validator={(value?: string) => {
          if (value !== password) {
            return [Messages.validation.confirmPassword];
          }
        }}
      />
    </InputFormCallout>
  );
};
