import React from "react";

import { Print, Star, Sync } from "@mui/icons-material";
import { Alert, AlertColor, AlertTitle } from "@mui/material";

import { OrderStatus } from "../apiClients/common";
import Messages from "../locale/en.json";

export interface StatusProps {
  type: OrderStatus;
}

const getSeverity = (type: OrderStatus): AlertColor => {
  switch (type) {
    case OrderStatus.Complete:
      return "success";
    case OrderStatus.Printing:
    case OrderStatus.Processing:
      return "warning";
    case OrderStatus.NewOrder:
    default:
      return "info";
  }
};

const getTitle = (type: OrderStatus): string => {
  switch (type) {
    case OrderStatus.Complete:
      return Messages.orderStatus.complete;
    case OrderStatus.NewOrder:
      return Messages.orderStatus.new;
    case OrderStatus.Printing:
      return Messages.orderStatus.printing;
    case OrderStatus.Processing:
      return Messages.orderStatus.processing;
    default:
      return type;
  }
};

const getIcon = (type: OrderStatus): JSX.Element | undefined => {
  switch (type) {
    // show default success mapping (we rely on the severity being success for OrderStatus.Complete)
    case OrderStatus.Complete:
      return undefined;
    case OrderStatus.NewOrder:
      return <Star />;
    case OrderStatus.Printing:
      return <Print />;
    case OrderStatus.Processing:
      return <Sync />;
    default:
      return undefined;
  }
};

export const Status = ({ type }: StatusProps): JSX.Element => {
  return (
    <Alert
      sx={{ height: "min-content", width: "min-content", paddingTop: 0, paddingBottom: 0 }}
      severity={getSeverity(type)}
      icon={getIcon(type)}
    >
      <AlertTitle style={{ margin: 0 }}>{getTitle(type)}</AlertTitle>
    </Alert>
  );
};
