import React from "react";

import Messages from "../../locale/en.json";
import { InputType } from "./common";
import { TextInput, TextInputProps } from "./TextInput";

export interface IntegerInputProps extends Omit<TextInputProps, "multiline" | "type"> {
  min?: number;
  max?: number;
}

const integerRegex = /^-?\d+$/;

export const IntegerInput = ({ validator, required, min, max, ...rest }: IntegerInputProps): JSX.Element => {
  const internalValidator = (value?: string): string[] | undefined => {
    const errors: string[] = [];

    const isInteger = integerRegex.test(value ?? "");
    const numValue = value ? parseInt(value, 10) : NaN;

    if ((required && !value) || (!required && value && !isInteger)) errors.push(Messages.validation.validInteger);
    if (isInteger) {
      if (min !== undefined && numValue < min) errors.push(Messages.validation.minInteger + min.toString());
      if (max !== undefined && numValue > max) errors.push(Messages.validation.maxInteger + max.toString());
    }

    errors.push(...(validator?.(value) ?? []));

    return errors.length ? errors : undefined;
  };

  return <TextInput {...rest} validator={internalValidator} required={required} type={InputType.Text} />;
};
