import React from "react";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup as RadioGroupInput,
} from "@mui/material";

import Messages from "../../locale/en.json";
import { FormContext, ValidationState } from "./Form";

export interface RadioGroupOption {
  id: string;
  text: string;
}

export interface RadioGroupProps {
  fieldName: string;
  label: string;
  required?: boolean;
  defaultValue?: string;
  onChange?: (id: string) => void;
  options: RadioGroupOption[];
}
export const RadioGroup = ({
  fieldName,
  label,
  defaultValue,
  onChange,
  options,
  required,
}: RadioGroupProps): JSX.Element => {
  const form = React.useContext(FormContext);

  const labelId = `${fieldName}-label`;

  const [errors, setErrors] = React.useState<string[]>([]);

  const fieldValidation = (): void => {
    const errorMessages: string[] = [];
    const value = form.getValue<RadioGroupOption>(fieldName);
    if (required && !value) {
      errorMessages.push(Messages.validation.required);
    }
    setErrors(errorMessages);
    form.setFieldState(errorMessages.length ? ValidationState.INVALID : ValidationState.VALID, fieldName);
  };

  const internalOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value || undefined;
    const option = value ? options.find(({ id }) => id === value) : undefined;
    form.setValue(option, fieldName);
    onChange?.(option?.id ?? "");
    if (required) {
      fieldValidation();
    }
  };

  React.useEffect(() => {
    if (required) {
      form.registerField(fieldName);
    }

    if (defaultValue) {
      const defaultOption = options.find(({ id }) => defaultValue === id);
      form.setValue(defaultOption, fieldName);
      if (required) {
        fieldValidation();
      }
    }

    return function cleanup() {
      if (required) {
        form.unregisterField(fieldName);
      }
      form.deleteField(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl>
      <FormLabel required={required} error={errors.length > 0} id={labelId}>
        {label}
      </FormLabel>
      <RadioGroupInput aria-labelledby={labelId} defaultValue={defaultValue} onChange={internalOnChange} name={fieldName}>
        {options.map((opt) => (
          <FormControlLabel key={opt.id} value={opt.id} control={<Radio />} label={opt.text} />
        ))}
      </RadioGroupInput>
      {errors.length ? <FormHelperText>{errors.join(" ")}</FormHelperText> : null}
    </FormControl>
  );
};
