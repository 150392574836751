import React from "react";

import { Add as AddIcon, Delete as DeleteIcon, Refresh as RefreshIcon } from "@mui/icons-material";

import { ProductDetails, ResponseError } from "../apiClients/common";
import { deleteProduct, getProducts } from "../apiClients/products";
import { AuthContext } from "../components/AuthContextProvider";
import { useBulkMutationCall } from "../hooks/useBulkMutationCall";
import { useQueryCall } from "../hooks/useQueryCall";
import { Action } from "../lib/ActionBar";
import { ConfirmDialog } from "../lib/ConfirmDialog";
import { FilterType } from "../lib/Filter";
import { Column, Listing, SelectMode } from "../lib/Listing";
import { NotificationManagerContext } from "../lib/NotificationManager";
import { NotificationType } from "../lib/NotificationTypes";
import { Page } from "../lib/Page";
import Messages from "../locale/en.json";
import { CreateProduct } from "../operations/CreateProduct";
import { EditProduct } from "../operations/EditProduct";

enum ColumnIds {
  Name = "name",
  Price = "price",
}

const columns: Column[] = [
  {
    id: ColumnIds.Name,
    text: Messages.labels.name,
    minWidth: 200,
  },
  {
    id: ColumnIds.Price,
    text: Messages.labels.basePrice,
    minWidth: 200,
  },
];

enum ActionIds {
  Create = "Create",
  Refresh = "Refresh",
  Delete = "Delete",
}

enum PanelIds {
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}

enum FilterIds {
  Search = "Search",
}

export const Products = (): JSX.Element => {
  const auth = React.useContext(AuthContext);
  const notificationManager = React.useContext(NotificationManagerContext);

  const [products, setProducts] = React.useState<ProductDetails[]>();
  const [search, setSearch] = React.useState<string>("");

  const { loading, refresh } = useQueryCall({
    method: getProducts,
    args: { search },
    onSuccess: setProducts,
    onFailure: (err?: ResponseError) => {
      if (err) {
        if (err?.status === 401) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.loadProducts,
            message: err.message,
          });
        }
      }
    },
  });

  const { loading: deleteLoading, invoke: invokeDelete } = useBulkMutationCall({
    method: deleteProduct,
    onSuccess: refresh,
    onFailure: (err?: ResponseError[]) => {
      if (err?.length) {
        if (err?.find((e) => e?.status === 401)) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.deleteProduct,
            message: err
              .map((e) => e?.message)
              .filter((message) => !!message)
              .join(" "),
          });
        }
      }
    },
  });

  const [editProduct, setEditProduct] = React.useState<ProductDetails>();
  const [selectedProducts, setSelectedProducts] = React.useState<ProductDetails[]>();
  const [openPanel, setOpenPanel] = React.useState<PanelIds>();

  const actions: Action[] = [
    {
      id: ActionIds.Refresh,
      disabled: loading,
      value: <RefreshIcon />,
      onClick: refresh,
    },
    {
      id: ActionIds.Delete,
      value: <DeleteIcon />,
      disabled: selectedProducts?.length !== 1 || deleteLoading,
      onClick: () => setOpenPanel(PanelIds.Delete),
    },
    {
      id: ActionIds.Create,
      value: <AddIcon />,
      onClick: () => setOpenPanel(PanelIds.Create),
    },
  ];

  const closePanels = (): void => {
    setOpenPanel(undefined);
  };

  const getPanel = (): JSX.Element | null => {
    switch (openPanel) {
      case PanelIds.Create:
        return (
          <CreateProduct
            title={Messages.common.create}
            onDiscard={closePanels}
            onExecute={() => {
              refresh();
              closePanels();
            }}
          />
        );
      case PanelIds.Update:
        return (
          <EditProduct
            title={Messages.common.update}
            productDetails={editProduct}
            onDiscard={closePanels}
            onExecute={() => {
              refresh();
              closePanels();
            }}
          />
        );
      case PanelIds.Delete: {
        let messagePrefix = "";

        if (selectedProducts?.length) {
          if (selectedProducts.length > 1) {
            messagePrefix = Messages.confirm.deleteProducts;
          } else {
            messagePrefix = Messages.confirm.deleteProduct;
          }
        }

        const message = messagePrefix + selectedProducts?.map((product) => product.name).join(", ");

        return (
          <ConfirmDialog
            message={message}
            onCancel={closePanels}
            onConfirm={() => {
              const productIds = selectedProducts?.map(({ id }) => ({ id })) ?? [];
              invokeDelete(productIds);
              closePanels();
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  const filters = [
    {
      id: FilterIds.Search,
      type: FilterType.Search,
      defaultValue: search,
      onChange: setSearch,
    },
  ];

  return (
    <Page actions={actions} title={Messages.page.products.title} loading={deleteLoading}>
      <Listing
        loading={loading}
        selectionMode={SelectMode.Single}
        onSelect={setSelectedProducts}
        onRowClick={(product: ProductDetails) => {
          setOpenPanel(PanelIds.Update);
          setEditProduct(product);
        }}
        items={products ?? []}
        columns={columns}
        filters={filters}
      />
      {getPanel()}
    </Page>
  );
};
