import React from "react";

import { NoValue } from "./NoValue";

export interface ListingCellProps {
  value?: any;
  formattedValue?: string;
}

const containerStyle: React.CSSProperties = {
  width: "100%",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const ListingCell = ({ formattedValue, value }: ListingCellProps): JSX.Element => {
  const displayValue = React.useMemo(() => formattedValue ?? value, []);

  return (
    <span style={containerStyle} title={displayValue}>
      {displayValue ?? <NoValue />}
    </span>
  );
};
