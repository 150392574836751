import React from "react";

import { Notification } from "./Notification";
import { NotificationInfo } from "./NotificationTypes";

interface NotificationOutletProps {
  notifications: NotificationInfo[];
}

export const NotificationOutlet = ({ notifications }: NotificationOutletProps): JSX.Element => {
  return (
    <>
      {notifications.map((notification) => (
        <Notification
          message={notification.message}
          key={notification.key}
          title={notification.title}
          type={notification.type}
          onClose={notification.onClose}
        />
      ))}
    </>
  );
};
