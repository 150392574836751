import React from "react";

import MenuClosedIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Drawer,
  DrawerProps,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { UserDetails } from "../apiClients/common";

export interface TopMenuItem {
  id: string;
  text: string | JSX.Element;
  onClick?: () => void;
}

export interface TopMenuProps {
  title: string;
  items?: TopMenuItem[];
  onProfileClick?: () => void;
  user?: UserDetails;
}

export const TopMenu = ({ title, items, onProfileClick, user }: TopMenuProps): JSX.Element => {
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const toggleDrawer = (): void => setDrawerOpen((open) => !open);

  return (
    <Box sx={{ flexGrow: 1, zIndex: 2 }}>
      <AppBar position="static" style={{ backgroundColor: "#3b3b3b" }}>
        <Toolbar sx={{ justifyContent: "space-between", flexWrap: "wrap" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              alignItems: "center",
              width: "max-content",
              justifyContent: "flex-start",
            }}
          >
            {items?.length ? (
              <IconButton
                onClick={toggleDrawer}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuClosedIcon />
              </IconButton>
            ) : null}
            <img style={{ maxHeight: "2rem" }} src="/logo.png" alt={title} />
          </div>
          {user ? <Profile onClick={onProfileClick} user={user} /> : null}
        </Toolbar>
      </AppBar>
      {items?.length ? <TopMenuList open={drawerOpen} onClose={toggleDrawer} items={items} /> : null}
    </Box>
  );
};

interface ProfileProps {
  onClick?: () => void;
  user: UserDetails;
}

const Profile = ({ onClick, user }: ProfileProps): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div style={{ display: "flex", flexFlow: "row" }}>
      <IconButton onClick={onClick}>
        <Avatar />
      </IconButton>
      {!fullScreen ? (
        <div style={{ display: "flex", flexFlow: "column", justifyContent: "center" }}>
          <span>
            {user.firstName} {user.lastName}
          </span>
          <span>{user.company}</span>
        </div>
      ) : null}
    </div>
  );
};

interface TopMenuListProps extends Pick<DrawerProps, "open"> {
  onClose?: () => void;
  items?: TopMenuItem[];
}

const TopMenuList = ({ open, onClose, items }: TopMenuListProps): JSX.Element => (
  <Drawer open={open} onClose={onClose} anchor="left" style={{ zIndex: 2 }}>
    <Box width={250}>
      <List>
        <ListItem>
          <Box width="100%" display="flex" justifyContent="flex-end">
            <IconButton onClick={onClose}>
              <MenuOpenIcon />
            </IconButton>
          </Box>
        </ListItem>
        {items?.map((item, index) => (
          <>
            <TopMenuListItem
              key={item.id}
              onClick={() => {
                item.onClick?.();
                onClose?.();
              }}
              text={item.text as string}
            />
            {index !== items.length - 1 ? <Divider /> : null}
          </>
        ))}
      </List>
    </Box>
  </Drawer>
);

interface TopMenuListItemProps {
  onClick?: () => void;
  text: string;
  selected?: boolean;
}

const TopMenuListItem = ({ text, onClick, selected }: TopMenuListItemProps): JSX.Element => (
  <ListItem onClick={onClick} style={{ backgroundColor: selected ? "#edebe9" : undefined }} disablePadding disableGutters>
    <ListItemButton style={{ padding: "1rem" }}>{text}</ListItemButton>
  </ListItem>
);
