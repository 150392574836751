import { buildUrl, CompanyDetails, handleResponse, ParsedResponse } from "./common";

export type CompanyDetailsCollection = CompanyDetails[];

export type GetCompaniesResponse = CompanyDetailsCollection;
type GetCompaniesReqQuery = { search?: string };

export const getCompanies = async (query?: GetCompaniesReqQuery): Promise<ParsedResponse<GetCompaniesResponse>> =>
  fetch(buildUrl("/api/companies", query), {
    method: "GET",
    credentials: "include",
  }).then(handleResponse<GetCompaniesResponse>);
