import React from "react";

import { useMediaQuery, useTheme } from "@mui/material";

import { Loading } from "../components/Loading";
import Messages from "../locale/en.json";
import { ActionBar, ActionBarProps } from "./ActionBar";
import { NotificationManagerContext } from "./NotificationManager";
import { NotificationType } from "./NotificationTypes";

export interface PageError {
  title: string;
  message: string;
}

export interface PageProps extends ActionBarProps {
  children: React.ReactNode;
  rootTitle?: string;
  loading?: boolean;
  error?: PageError;
}

export const Page = ({
  children,
  title,
  actions,
  rootTitle = Messages.cresigns,
  loading,
  error,
}: PageProps): JSX.Element => {
  const notificationManager = React.useContext(NotificationManagerContext);
  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.down("md"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const spacing = fullScreen ? "0.5rem" : medium ? "1rem" : "2rem";

  React.useEffect(() => {
    document.title = title ? `${title} | ${rootTitle}` : rootTitle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (error) {
      notificationManager.push({ type: NotificationType.Error, ...error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(error)]);

  return (
    <div style={{ margin: spacing }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: spacing,
          width: "100%",
          height: "100%",
        }}
      >
        {actions?.length ? <ActionBar title={title} actions={actions} /> : null}
        {children}
      </div>
      {loading ? <Loading /> : null}
    </div>
  );
};
