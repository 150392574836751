import React from "react";

import { Box, Button, Dialog } from "@mui/material";

import Messages from "../locale/en.json";

export interface ConfirmDialogProps {
  message: string;
  /**
   * @default confirm
   */
  confirmButtonText?: string;
  /**
   * @default cancel
   */
  cancelButtonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const ConfirmDialog = ({
  message,
  confirmButtonText = Messages.common.confirm,
  cancelButtonText = Messages.common.cancel,
  onCancel,
  onConfirm,
}: ConfirmDialogProps): JSX.Element => (
  <Dialog open>
    <Box display="flex" padding="2rem 2rem 0rem 2rem" flexDirection="column" gap="1rem">
      {message}
      <div style={{ display: "flex", gap: "2rem", justifyContent: "flex-start", width: "100%", margin: "1rem 0 2rem 0" }}>
        <Button type="submit" onClick={onConfirm} variant="contained">
          {confirmButtonText}
        </Button>
        <Button type="reset" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </Box>
  </Dialog>
);
