import React from "react";
import { useNavigate } from "react-router";

import { Add as AddIcon, Delete as DeleteIcon, Refresh as RefreshIcon } from "@mui/icons-material";

import { OrderStatus, PurchaseOrderSummary, ResponseError, UserType } from "../apiClients/common";
import { deletePurchaseOrder, getPurchaseOrders, PurchaseOrderDetailsCollection } from "../apiClients/purchaseOrders";
import { AuthContext } from "../components/AuthContextProvider";
import { PurchaseOrderList } from "../components/PurchaseOrderList";
import { OrderPath, PageId, PageRegistrationConfig } from "../constants";
import { useBulkMutationCall } from "../hooks/useBulkMutationCall";
import { useQueryCall } from "../hooks/useQueryCall";
import { Action } from "../lib/ActionBar";
import { ConfirmDialog } from "../lib/ConfirmDialog";
import { FilterType } from "../lib/Filter";
import { ListingFilterProps, SelectMode } from "../lib/Listing";
import { NotificationManagerContext } from "../lib/NotificationManager";
import { NotificationType } from "../lib/NotificationTypes";
import { Page } from "../lib/Page";
import Messages from "../locale/en.json";

enum FilterIds {
  From = "From",
  To = "To",
  Search = "Search",
}

enum ActionIds {
  Create = "Create",
  Refresh = "Refresh",
  Delete = "Delete",
}

enum PanelIds {
  Delete = "Delete",
}

export const NewOrders = (): JSX.Element => {
  const auth = React.useContext(AuthContext);
  const notificationManager = React.useContext(NotificationManagerContext);

  const [search, setSearch] = React.useState<string>("");

  const navigate = useNavigate();

  const [purchaseOrders, setPurchaseOrders] = React.useState<PurchaseOrderDetailsCollection>();

  const { loading, refresh } = useQueryCall({
    method: getPurchaseOrders,
    args: { status: OrderStatus.NewOrder, search },
    onSuccess: setPurchaseOrders,
    onFailure: (err?: ResponseError) => {
      if (err) {
        if (err.status === 401) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.loadNewOrders,
            message: err.message,
          });
        }
      }
    },
  });

  const { loading: deleteLoading, invoke: invokeDelete } = useBulkMutationCall({
    method: deletePurchaseOrder,
    onSuccess: refresh,
    onFailure: (error?: ResponseError[]) => {
      if (error?.length) {
        if (error.find((e) => e?.status === 401)) {
          auth.logout();
        } else {
          notificationManager.push({
            type: NotificationType.Error,
            title: Messages.errors.deleteOrder,
            message: error
              .map((err) => err?.message)
              .filter((message) => !!message)
              .join(" "),
          });
        }
      }
    },
  });

  const [selectedPurchaseOrders, setSelectedPurchaseOrders] = React.useState<PurchaseOrderDetailsCollection>();
  const [openPanel, setOpenPanel] = React.useState<PanelIds>();

  const closePanels = (): void => {
    setOpenPanel(undefined);
  };

  const actions: Action[] = [
    {
      id: ActionIds.Refresh,
      disabled: loading,
      value: <RefreshIcon />,
      onClick: refresh,
    },
  ];

  if (auth.user?.type === UserType.Admin || auth.user?.type === UserType.SuperAdmin) {
    actions.push({
      id: ActionIds.Delete,
      value: <DeleteIcon />,
      disabled: selectedPurchaseOrders?.length !== 1 || deleteLoading,
      onClick: () => setOpenPanel(PanelIds.Delete),
    });
  }

  actions.push({
    id: ActionIds.Create,
    value: <AddIcon />,
    onClick: () => navigate(PageRegistrationConfig[PageId.Order].path),
  });

  const getPanel = (): JSX.Element | null => {
    switch (openPanel) {
      case PanelIds.Delete: {
        let messagePrefix = "";

        if (selectedPurchaseOrders?.length) {
          if (selectedPurchaseOrders.length > 1) {
            messagePrefix = Messages.confirm.deletePurchaseOrders;
          } else {
            messagePrefix = Messages.confirm.deletePurchaseOrder;
          }
        }

        const message = messagePrefix + selectedPurchaseOrders?.map((po) => po.purchaseOrderNumber ?? po.id).join(", ");

        return (
          <ConfirmDialog
            message={message}
            onCancel={closePanels}
            onConfirm={() => {
              const poIds = selectedPurchaseOrders?.map(({ id }) => ({ id })) ?? [];
              invokeDelete(poIds);
              closePanels();
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  const filters = [
    {
      id: FilterIds.Search,
      type: FilterType.Search,
      defaultValue: search,
      onChange: setSearch,
    },
  ];

  const getOnSelect = (type?: UserType): ((items: PurchaseOrderSummary[]) => void) | undefined => {
    switch (type) {
      case UserType.SuperAdmin:
      case UserType.Admin:
        return setSelectedPurchaseOrders;
      case UserType.Client:
      default:
        return undefined;
    }
  };

  const getOnRowClick = (type?: UserType): ((item: PurchaseOrderSummary) => void) | undefined => {
    switch (type) {
      case UserType.SuperAdmin:
      case UserType.Admin:
      case UserType.Client:
        return (item: PurchaseOrderSummary) => navigate(`/${OrderPath}/${item.id}/`);
      default:
        return undefined;
    }
  };

  return (
    <Page actions={actions} title={Messages.page.newOrders.title} loading={deleteLoading}>
      <PurchaseOrderList
        loading={loading}
        selectionMode={
          auth.user?.type === UserType.SuperAdmin || auth.user?.type === UserType.Admin
            ? SelectMode.Single
            : SelectMode.None
        }
        filters={filters as ListingFilterProps<Date | string, PurchaseOrderSummary>[]}
        items={purchaseOrders ?? []}
        onSelect={getOnSelect(auth.user?.type)}
        userType={auth.user?.type}
        onRowClick={getOnRowClick(auth.user?.type)}
      />
      {getPanel()}
    </Page>
  );
};
