export enum PageId {
  Notes = "Notes",
  NewOrders = "NewOrders",
  ActiveOrders = "ActiveOrders",
  CompletedOrders = "CompletedOrders",
  Order = "Order",
  EditOrder = "EditOrder",
  Products = "Products",
  Users = "Users",
}

export const NotesPath = "notes";
export const NewOrdersPath = "newOrders";
export const ActiveOrdersPath = "activeOrders";
export const CompletedOrdersPath = "completedOrders";
export const OrderPath = "orders";
export const ProductsPath = "products";
export const UsersPath = "users";

export interface NavigationEntry {
  path: string;
}

export const PageRegistrationConfig: Record<PageId, NavigationEntry> = {
  [PageId.Notes]: {
    path: `/${NotesPath}/`,
  },
  [PageId.NewOrders]: {
    path: `/${NewOrdersPath}/`,
  },
  [PageId.ActiveOrders]: {
    path: `/${ActiveOrdersPath}/`,
  },
  [PageId.CompletedOrders]: {
    path: `/${CompletedOrdersPath}/`,
  },
  [PageId.Order]: {
    path: `/${OrderPath}/`,
  },
  [PageId.EditOrder]: {
    path: `/${OrderPath}/:id/`,
  },
  [PageId.Products]: {
    path: `/${ProductsPath}/`,
  },
  [PageId.Users]: {
    path: `/${UsersPath}/`,
  },
};
