export enum UserType {
  Client = "Client",
  Admin = "Admin",
  SuperAdmin = "SuperAdmin",
}

export interface CompanyDetails {
  id: number;
  name: string;
  createdOn: number;
  updatedOn: number;
}

export interface UserDetails {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  type: UserType;
  createdOn: number;
  updatedOn: number;
}

export interface PurchaseOrderLineDetails {
  id: number;
  product: ProductDetails;
  name: string;
  quantity: number;
  basePrice: string;
  price?: string;
  createdOn: number;
  updatedOn: number;
}

export type PurchaseOrderLineSummary = {
  id: number;
  name: string;
  product: ProductDetails | null;
  quantity: number;
  price?: string;
};

export type NewPurchaseOrderLine = {
  product: ProductDetails | null;
  quantity: number;
  price?: string;
};

export interface ProductDetails {
  id: number;
  name: string;
  price: string;
  createdOn: number;
  updatedOn: number;
}

export enum PropertyType {
  Office = "Office",
  ConstructionSite = "ConstructionSite",
  Other = "Other",
}

export enum OrderStatus {
  // Not submitted
  NewOrder = "NewOrder",
  // Submitted
  Processing = "Processing",
  // Accepted
  Printing = "Printing",
  // Finished
  Complete = "Complete",
}

export interface PurchaseOrderDetails {
  id: number;
  status: OrderStatus;
  details: PurchaseOrderSummary | undefined;
  notes: NoteDetails[] | undefined;
  items: PurchaseOrderLineDetails[] | undefined;
}

export type PurchaseOrderSummary = {
  completedOn?: Date;
  createdOn: Date;
  userNotes?: string;
  id: number;
  permissionFromOwnerGiven: boolean;
  status: OrderStatus;
  submittedOn?: Date;
  updatedOn: Date;
  address?: string;
  agent?: string;
  city?: string;
  intersection?: string;
  propertyType?: PropertyType;
  purchaseOrderNumber?: string;
  signColours?: string;
  client: UserDetails;
};

export type NewPurchaseOrderBody = Omit<
  PurchaseOrderSummary,
  "id" | "createdOn" | "updatedOn" | "completedOn" | "submittedOn" | "details" | "client" | "items" | "status"
>;
export type UpdatePurchaseOrderBody = Omit<
  PurchaseOrderSummary,
  "id" | "createdOn" | "updatedOn" | "completedOn" | "submittedOn" | "details" | "client" | "items" | "status"
>;

export interface NoteDetails {
  id: number;
  createdOn: Date;
  note: string;
  attachments?: AttachmentDetails[];
  user: UserDetails;
  purchaseOrder?: PurchaseOrderSummary;
  deletedOn?: string;
}

export interface AttachmentDetails {
  id: number;
  name: string;
  path: string;
  attachment: File;
}

export interface ResponseError {
  status?: number;
  message: string;
}

export interface ParsedResponse<DataType> {
  data: DataType | undefined;
  error: ResponseError | undefined;
}

export const handleResponse = async <DataType>(res: Response): Promise<ParsedResponse<DataType>> => {
  let data: DataType | undefined;
  let error: ResponseError | undefined;

  if (!res.ok) {
    try {
      const body = await res.json();
      error = { status: res.status, message: body.message || res.statusText };
    } catch (e) {
      console.error((e as Error).message);
      error = { status: res.status, message: res.statusText };
    }
  } else {
    try {
      const body = await res.json();
      data = body;
    } catch (e) {
      console.error((e as Error).message);
      data = undefined;
    }
  }

  return { data, error };
};

export const buildUrl = (url: string, query: Record<string, string | undefined> | undefined): string => {
  if (!query || !Object.values(query).length) {
    return url;
  }

  const init: Record<string, string> = Object.fromEntries(Object.entries(query).filter(([, val]) => !!val)) as Record<
    string,
    string
  >;

  return `${url}?${new URLSearchParams(init)}`;
};
