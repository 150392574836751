import React from "react";

import { Nav } from "../components/Nav";
import Messages from "../locale/en.json";

export const Error = () => {
  return (
    <>
      <Nav />
      <h1>{Messages.page.error.title}</h1>
    </>
  );
};
