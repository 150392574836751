import React from "react";

import { UserType } from "../apiClients/common";
import { getUsers, UserDetailsCollection } from "../apiClients/users";
import { useQueryCall } from "../hooks/useQueryCall";
import { Select, SelectOption, SelectProps } from "../lib/Input/Select";

export interface UserSelectProps extends Omit<SelectProps, "options" | "search" | "loading"> {
  type?: UserType;
}

const responseToSelectOptions = (response?: UserDetailsCollection): SelectOption[] => {
  if (!response || !response.length) return [];

  const opts: SelectOption[] = response.map((user) => ({
    id: user.id.toString(),
    text: `${user.firstName} ${user.lastName} (${user.company})`,
    data: user,
  }));

  return opts;
};

export const UserSelect = ({ type, ...rest }: UserSelectProps): JSX.Element => {
  const [options, setOptions] = React.useState<SelectOption[]>([]);

  const { loading } = useQueryCall({
    method: getUsers,
    args: { type },
    onSuccess: (response) => setOptions(responseToSelectOptions(response)),
  });

  return <Select search loading={loading} options={options} {...rest} />;
};
