import React from "react";

import { ParsedResponse, ResponseError } from "../apiClients/common";

export interface UseBulkMutationResult<ArgsType, DataType> {
  loading: boolean;
  data?: (DataType | undefined)[] | undefined;
  error?: (ResponseError | undefined)[] | undefined;
  invoke: ArgsType extends never ? () => void : (args?: ArgsType[]) => void;
}

export interface UseBulkMutationProps<ArgsType, DataType> {
  method: ArgsType extends never
    ? () => Promise<ParsedResponse<DataType>>
    : (args: ArgsType) => Promise<ParsedResponse<DataType>>;
}

export const useBulkMutation = <ArgsType, DataType>({
  method,
}: UseBulkMutationProps<ArgsType, DataType>): UseBulkMutationResult<ArgsType, DataType> => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<(DataType | undefined)[]>();
  const [errors, setErrors] = React.useState<(ResponseError | undefined)[]>();

  const invoke = React.useCallback((args: ArgsType[]) => {
    // always reset state when executing new query
    setLoading(true);
    setErrors(undefined);
    setResponse(undefined);

    Promise.all(args.map((arg) => method(arg)))
      .then((res) => {
        const _errors: (ResponseError | undefined)[] = [];
        const responses: (DataType | undefined)[] = [];
        res.forEach(({ data, error }) => {
          _errors.push(error);
          responses.push(data);
        });
        setErrors(_errors);
        setResponse(responses);
      })
      .catch((err) => setErrors(err.map((e: Error) => ({ message: e.message }))))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) as UseBulkMutationResult<ArgsType, DataType>["invoke"];

  return {
    loading,
    error: errors,
    invoke,
    data: response,
  };
};
