import React from "react";
import { v4 as uuid } from "uuid";

import { NotificationInfo } from "./NotificationTypes";

export interface NotificationManagerProviderProps {
  children: JSX.Element;
  onNotificationsChanged?: (notifications: NotificationInfo[]) => void;
}

type NewNotification = Omit<NotificationInfo, "key" | "onClose">;
interface NotificationManager {
  push: (notification: NewNotification) => void;
}

export const NotificationManagerContext = React.createContext<NotificationManager>({} as NotificationManager);

export const NotificationManagerProvider = ({
  children,
  onNotificationsChanged,
}: NotificationManagerProviderProps): JSX.Element => {
  const [notifications, setNotifications] = React.useState<NotificationInfo[]>([]);

  const dismissNotification = React.useCallback((key: string): void => {
    setNotifications((prev) => prev.filter((n) => n.key !== key));
  }, []);

  const addNotification = React.useCallback((notification: NewNotification): void => {
    const key = uuid();
    const onClose = () => dismissNotification(key);
    const newNotification: NotificationInfo = { ...notification, key, onClose };
    setNotifications((prev) => [...prev, newNotification]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const manager = React.useMemo<NotificationManager>(() => ({ push: addNotification }), []);

  React.useEffect(() => {
    onNotificationsChanged?.(notifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(notifications)]);

  return <NotificationManagerContext.Provider value={manager}>{children}</NotificationManagerContext.Provider>;
};
