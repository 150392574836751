import React from "react";

import { TextField, TextFieldProps } from "@mui/material";

import Messages from "../../locale/en.json";
import { InputType } from "./common";
import { FormContext, ValidationMode, ValidationModeContext, ValidationState } from "./Form";

export interface MultilineConfig extends Pick<TextFieldProps, "maxRows" | "minRows"> {
  /**
   * @default minRows
   */
  rows?: number;
}

type BaseTextFieldProps = Pick<TextFieldProps, "disabled" | "placeholder" | "required" | "label">;

export interface TextInputProps extends BaseTextFieldProps {
  fieldName: string;
  multiline?: MultilineConfig;
  /**
   * @default text
   */
  type?: InputType.Text | InputType.Password | InputType.Email;
  defaultValue?: string;
  onChange?: (value?: string) => void;
  validator?: (value?: string) => string[] | undefined;
  /**
   * @default 18rem
   */
  width?: React.CSSProperties["width"];
  autoComplete?: "off";
}

export const TextInput = ({
  label,
  fieldName,
  defaultValue,
  disabled,
  multiline,
  onChange,
  placeholder,
  required,
  type,
  validator,
  width = "18rem",
  autoComplete,
}: TextInputProps): JSX.Element => {
  const form = React.useContext(FormContext);
  const validationMode = React.useContext(ValidationModeContext);
  const [errors, setErrors] = React.useState<string[]>([]);

  const internalOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.currentTarget.value || undefined;
    form.setValue(newValue, fieldName);
    onChange?.(newValue);
    if (required || validator) {
      fieldValidation();
    }
  };

  const fieldValidation = (): void => {
    const errorMessages: string[] = [];
    const currentValue = form.getValue<string>(fieldName);
    errorMessages.push(...(validator?.(currentValue) ?? []));
    if (required && !currentValue) {
      errorMessages.push(Messages.validation.required);
    }
    form.setFieldState(errorMessages.length ? ValidationState.INVALID : ValidationState.VALID, fieldName);
    setErrors(errorMessages);
  };

  React.useEffect(() => {
    if (required || validator) {
      form.registerField(fieldName);
    }

    if (defaultValue) {
      form.setValue(defaultValue, fieldName);
      if (required || validator) {
        fieldValidation();
      }
    }

    return function cleanup() {
      if (required || validator) {
        form.unregisterField(fieldName);
      }
      form.deleteField(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (validationMode === ValidationMode.ON) {
      if (required || validator) {
        fieldValidation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode]);

  return (
    <TextField
      id={fieldName}
      label={label}
      style={{ width }}
      name={fieldName}
      multiline={!!multiline}
      rows={multiline?.rows ?? multiline?.minRows}
      minRows={multiline?.minRows}
      maxRows={multiline?.maxRows}
      onChange={internalOnChange}
      defaultValue={defaultValue}
      disabled={disabled}
      required={required}
      placeholder={placeholder}
      type={type}
      error={errors.length > 0}
      helperText={errors.join(" ")}
      autoComplete={autoComplete}
    />
  );
};
