import React from "react";

import { Box, Dialog, useMediaQuery, useTheme } from "@mui/material";

export enum FlowType {
  Block = "Block",
  Overlay = "Overlay",
}

export interface CalloutProps {
  onClose?: () => void;
  backdrop?: boolean;
  children: JSX.Element;
  width?: string;
  /**
   * @default Block
   */
  flow?: FlowType;
}

export const Callout = ({ backdrop, onClose, children, width, flow = FlowType.Block }: CalloutProps): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open
      onClose={onClose}
      fullScreen={fullScreen}
      style={{ zIndex: 1, ...(flow === FlowType.Block ? { position: "initial" } : {}) }}
      hideBackdrop={!backdrop}
      disableEnforceFocus={flow === FlowType.Block}
      PaperProps={{ style: { width, maxWidth: width ? "100%" : undefined, overflow: "auto" } }}
    >
      <Box display="flex" padding="0rem 2rem" flexDirection="column" gap="1rem">
        {children}
      </Box>
    </Dialog>
  );
};
