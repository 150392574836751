export enum ContentType {
  Data = "Data",
  Text = "Text",
}

export const readFile = async (file: File, outputType: ContentType): Promise<string> =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader();

    const onError = (e: Event): void => reject(e);
    const onLoad = (e: ProgressEvent<FileReader>): void => {
      resolve(e.target?.result?.toString() ?? "");
    };

    reader.onerror = onError;
    reader.onload = onLoad;

    switch (outputType) {
      case ContentType.Data:
        reader.readAsDataURL(file);
        break;
      case ContentType.Text:
      default:
        reader.readAsText(file, "utf-8");
        break;
    }
  });

export const getBase64Str = (base64: string): string => {
  let data = base64;

  if (data) {
    const base64Location = data.indexOf(";base64,");
    if (base64Location > -1) {
      data = data.substring(base64Location + 8);
    }
  }

  return data;
};
