import React from "react";

import { Button, Typography } from "@mui/material";

import Messages from "../../locale/en.json";
import { Form, FormComponentRef, FormProps, OnValueUpdateCallback, ValidationState } from "../Input/Form";

enum PrimaryAction {
  Wait = "Wait",
  Click = "Click",
}

export interface InputFormComponentRef {
  allowResubmit: () => void;
}

export interface InputFormProps extends Pick<FormProps, "children" | "onSubmit"> {
  title?: string;
  /**
   * @default submit
   */
  submitButtonText?: string;
  /**
   * @default discard
   */
  discardButtonText?: string;
  /**
   * Callback triggered when the user discards any changes made
   */
  onDiscard?: () => void;
  componentRef?: (ref: InputFormComponentRef) => void;
  onValueUpdate?: OnValueUpdateCallback;
}

export const InputForm = ({
  children,
  title,
  onSubmit,
  onDiscard,
  submitButtonText = Messages.common.submit,
  discardButtonText = Messages.common.discard,
  componentRef,
  onValueUpdate,
}: InputFormProps): JSX.Element => {
  const [formComponentRef, setFormComponentRef] = React.useState<FormComponentRef>();

  const [overallValidationState, setOverallValidationState] = React.useState<ValidationState>();
  const [primaryAction, setPrimaryAction] = React.useState<PrimaryAction>(PrimaryAction.Wait);

  const onSubmitClicked = (): void => {
    formComponentRef?.enableValidation();
    setPrimaryAction(PrimaryAction.Click);
  };

  React.useEffect(() => {
    componentRef?.({ allowResubmit: () => setPrimaryAction(PrimaryAction.Wait) });
  }, [componentRef]);

  React.useEffect(() => {
    if (primaryAction === PrimaryAction.Click) {
      if (overallValidationState === ValidationState.INVALID) {
        setPrimaryAction(PrimaryAction.Wait);
      } else if (!overallValidationState || overallValidationState === ValidationState.VALID) {
        formComponentRef?.submit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryAction, overallValidationState]);

  return (
    <Form
      componentRef={setFormComponentRef}
      onSubmit={onSubmit}
      onStatesUpdate={setOverallValidationState}
      onValueUpdate={onValueUpdate}
    >
      {title ? (
        <div style={{ margin: "2rem 0 1rem 0", width: "100%", justifyContent: "flex-start" }}>
          <Typography variant="h2" style={{ width: "max-content", justifySelf: "flex-start", fontSize: "2rem" }}>
            {title}
          </Typography>
        </div>
      ) : null}
      {children}
      <div style={{ display: "flex", gap: "2rem", justifyContent: "flex-start", width: "100%", margin: "1rem 0 2rem 0" }}>
        <Button
          type="submit"
          onClick={onSubmitClicked}
          variant="contained"
          disabled={overallValidationState === ValidationState.INVALID}
        >
          {submitButtonText}
        </Button>
        <Button type="reset" onClick={onDiscard}>
          {discardButtonText}
        </Button>
      </div>
    </Form>
  );
};
